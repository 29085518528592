import React, { useState, useEffect } from 'react';
import { fetchUpcomingContests } from '../../services/contestServices';
import './UpcomingContests.css';

const UpcomingContests = () => {
  const [contests, setContests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState('all');
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    const loadContests = async () => {
      try {
        setLoading(true);
        setError(null);
        const upcomingContests = await fetchUpcomingContests();
        setContests(upcomingContests);
        setLastUpdated(new Date());
      } catch (error) {
        console.error('Error loading contests:', error);
        setError('Failed to load upcoming contests. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadContests();
    // Refresh contests every 2 minutes
    const intervalId = setInterval(loadContests, 2 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  const formatDate = (dateString) => {
    const options = { 
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short'
    };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const getPlatformColor = (platform) => {
    switch (platform.toLowerCase()) {
      case 'leetcode':
        return '#FFA116';
      case 'codeforces':
        return '#318CE7';
      case 'codechef':
        return '#5B4638';
      default:
        return '#666666';
    }
  };

  const filteredContests = selectedPlatform === 'all'
    ? contests
    : contests.filter(contest => contest.platform.toLowerCase() === selectedPlatform.toLowerCase());

  const getTimeUntilContest = (startTime) => {
    const now = new Date();
    const contestTime = new Date(startTime);
    const diffTime = contestTime - now;
    
    const days = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
    
    if (days > 0) return `${days}d ${hours}h`;
    if (hours > 0) return `${hours}h ${minutes}m`;
    return `${minutes}m`;
  };

  const getLastUpdatedText = () => {
    if (!lastUpdated) return '';
    const now = new Date();
    const diff = Math.floor((now - lastUpdated) / 1000);
    if (diff < 60) return 'Updated just now';
    if (diff < 3600) return `Updated ${Math.floor(diff / 60)} minutes ago`;
    return `Updated ${Math.floor(diff / 3600)} hours ago`;
  };

  if (loading) {
    return (
      <div className="contests-loading">
        <div className="loading-spinner"></div>
        <p>Loading upcoming contests...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="contests-error">
        <p>{error}</p>
        <button onClick={() => window.location.reload()} className="retry-button">
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="upcoming-contests">
      <div className="contests-header">
        <div className="header-main">
          <h2>Upcoming Contests</h2>
          <span className="last-updated">{getLastUpdatedText()}</span>
        </div>
        <div className="platform-filter">
          <select 
            value={selectedPlatform} 
            onChange={(e) => setSelectedPlatform(e.target.value)}
          >
            <option value="all">All Platforms</option>
            <option value="leetcode">LeetCode</option>
            <option value="codeforces">CodeForces</option>
            <option value="codechef">CodeChef</option>
          </select>
        </div>
      </div>

      {filteredContests.length === 0 ? (
        <div className="no-contests">
          <p>
            {selectedPlatform === 'all'
              ? 'No upcoming contests found at the moment.'
              : `No upcoming contests found for ${selectedPlatform}.`}
          </p>
          <p className="check-back">Please check back later for updates.</p>
        </div>
      ) : (
        <div className="contests-grid">
          {filteredContests.map(contest => (
            <div key={contest.id} className="contest-card">
              <div 
                className="contest-platform" 
                style={{ backgroundColor: getPlatformColor(contest.platform) }}
              >
                {contest.platform}
              </div>
              <h3>{contest.title}</h3>
              <div className="contest-details">
                <p><strong>Starts:</strong> {formatDate(contest.startTime)}</p>
                <p><strong>Duration:</strong> {contest.duration}</p>
                <p><strong>Difficulty:</strong> {contest.difficulty}</p>
                <p className="time-until">
                  <strong>Starts in:</strong> {getTimeUntilContest(contest.startTime)}
                </p>
              </div>
              <a 
                href={contest.url} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="register-button"
              >
                Register Now
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UpcomingContests;
