import React, { useState, useEffect } from 'react';
import { auth, db } from '../../firebase/config';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { fetchAllPlatformStats } from '../../services/api';
import { Line } from 'react-chartjs-2';
import { FaShare, FaCheck } from 'react-icons/fa';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import './UserProfile.css';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const UserProfile = () => {
  const [loading, setLoading] = useState(true);
  const [usernames, setUsernames] = useState({
    leetcodeUsername: '',
    codeforcesHandle: '',
    codechefUsername: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const [stats, setStats] = useState(null);
  const [error, setError] = useState('');
  const [shareUrl, setShareUrl] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    loadUserData();
  }, []);

  const loadUserData = async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const data = userDoc.data();
        setUsernames(data.platforms || {});
        if (data.platforms) {
          fetchStats(data.platforms);
        }
        // Generate share URL if it doesn't exist
        if (!data.shareId) {
          const shareId = generateShareId();
          await updateDoc(doc(db, 'users', userId), {
            shareId: shareId
          });
          setShareUrl(`${window.location.origin}/share/${shareId}`);
        } else {
          setShareUrl(`${window.location.origin}/share/${data.shareId}`);
        }
      }
    } catch (error) {
      console.error('Error loading user data:', error);
      setError('Failed to load user data');
    } finally {
      setLoading(false);
    }
  };

  const generateShareId = () => {
    return Math.random().toString(36).substring(2, 15) + 
           Math.random().toString(36).substring(2, 15);
  };

  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const fetchStats = async (platforms) => {
    try {
      const allStats = await fetchAllPlatformStats(platforms);
      setStats(allStats);
    } catch (error) {
      console.error('Error fetching stats:', error);
      setError('Failed to fetch platform statistics');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const userId = auth.currentUser?.uid;
      if (!userId) throw new Error('User not authenticated');

      await setDoc(doc(db, 'users', userId), {
        platforms: usernames
      }, { merge: true });

      await fetchStats(usernames);
      setIsEditing(false); // Exit edit mode after saving
    } catch (error) {
      console.error('Error saving user data:', error);
      setError('Failed to save user data');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUsernames(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const getRatingChartData = () => {
    if (!stats) return null;

    const datasets = [];

    // Add CodeForces data
    if (stats.codeforces?.ratingHistory?.length > 0) {
      const cfData = stats.codeforces.ratingHistory.map(entry => ({
        x: new Date(entry.ratingUpdateTimeSeconds * 1000).toISOString(),
        y: entry.newRating
      }));
      datasets.push({
        label: 'CodeForces',
        data: cfData,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.1
      });
    }

    // Add CodeChef data
    if (stats.codechef?.ratingHistory?.length > 0) {
      const ccData = stats.codechef.ratingHistory.map(entry => ({
        x: new Date(entry.timestamp).toISOString(),
        y: entry.rating
      }));
      datasets.push({
        label: 'CodeChef',
        data: ccData,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.1
      });
    }

    return {
      datasets
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        align: 'center',
        labels: {
          padding: 20
        }
      },
      title: {
        display: true,
        text: 'Rating Progress Over Time',
        align: 'start',
        font: {
          size: 16,
          weight: 'bold'
        },
        padding: {
          top: 10,
          bottom: 30
        }
      }
    },
    scales: {
      x: {
        type: 'time',
        adapters: {
          date: {
            locale: enUS
          }
        },
        time: {
          unit: 'month',
          displayFormats: {
            month: 'MMM yyyy'
          }
        },
        title: {
          display: true,
          text: 'Date',
          padding: {
            top: 10
          }
        }
      },
      y: {
        title: {
          display: true,
          text: 'Rating',
          padding: {
            bottom: 10
          }
        },
        min: 0
      }
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="profile-container">
      <div className="section-header">
        <h2>Platform Usernames</h2>
        <div className="header-actions">
          {!isEditing && (
            <button 
              className="edit-button"
              onClick={() => setIsEditing(true)}
            >
              Edit Usernames
            </button>
          )}
          <button 
            className={`share-button ${copied ? 'copied' : ''}`}
            onClick={handleShare}
          >
            {copied ? (
              <>
                <FaCheck /> Copied!
              </>
            ) : (
              <>
                <FaShare /> Share Profile
              </>
            )}
          </button>
        </div>
      </div>
      
      {error && <div className="error-message">{error}</div>}
      
      <form onSubmit={handleSubmit} className="platform-form">
        <div className="form-group">
          <label>LeetCode Username:</label>
          {isEditing ? (
            <input
              type="text"
              name="leetcodeUsername"
              value={usernames.leetcodeUsername}
              onChange={handleChange}
              placeholder="Enter LeetCode username"
            />
          ) : (
            <span className="username-display">
              {usernames.leetcodeUsername || 'Not set'}
            </span>
          )}
        </div>

        <div className="form-group">
          <label>CodeForces Handle:</label>
          {isEditing ? (
            <input
              type="text"
              name="codeforcesHandle"
              value={usernames.codeforcesHandle}
              onChange={handleChange}
              placeholder="Enter CodeForces handle"
            />
          ) : (
            <span className="username-display">
              {usernames.codeforcesHandle || 'Not set'}
            </span>
          )}
        </div>

        <div className="form-group">
          <label>CodeChef Username:</label>
          {isEditing ? (
            <input
              type="text"
              name="codechefUsername"
              value={usernames.codechefUsername}
              onChange={handleChange}
              placeholder="Enter CodeChef username"
            />
          ) : (
            <span className="username-display">
              {usernames.codechefUsername || 'Not set'}
            </span>
          )}
        </div>

        {isEditing && (
          <div className="button-group">
            <button type="submit" className="save-button">
              Save Changes
            </button>
            <button 
              type="button" 
              className="cancel-button"
              onClick={() => {
                setIsEditing(false);
                loadUserData(); // Reset to saved values
              }}
            >
              Cancel
            </button>
          </div>
        )}
      </form>

      {stats && (
        <>
          <div className="platform-stats-grid">
            <div className="platform-card">
              <h3>LeetCode</h3>
              <div className="stat-item">
                <span>Problems Solved:</span>
                <span>{stats.leetcode?.totalSolved || '0'}</span>
              </div>
              <div className="stat-item">
                <span>Acceptance Rate:</span>
                <span>{stats.leetcode?.acceptanceRate || '0'}%</span>
              </div>
            </div>

            <div className="platform-card">
              <h3>CodeForces</h3>
              <div className="stat-item">
                <span>Rating:</span>
                <span>{stats.codeforces?.result?.[0]?.rating || '0'}</span>
              </div>
              <div className="stat-item">
                <span>Max Rating:</span>
                <span>{stats.codeforces?.result?.[0]?.maxRating || '0'}</span>
              </div>
            </div>

            <div className="platform-card">
              <h3>CodeChef</h3>
              <div className="stat-item">
                <span>Rating:</span>
                <span>{stats.codechef?.currentRating || '0'}</span>
              </div>
              <div className="stat-item">
                <span>Problems Solved:</span>
                <span>{stats.codechef?.problemsSolved || '0'}</span>
              </div>
            </div>
          </div>

          <h2 className="section-title">Rating Progress Over Time</h2>
          
          <div className="chart-container">
            {getRatingChartData() && (
              <Line 
                data={getRatingChartData()} 
                options={{
                  ...chartOptions,
                  plugins: {
                    ...chartOptions.plugins,
                    title: {
                      display: false // Remove the chart's built-in title
                    }
                  }
                }}
                height={300}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UserProfile;
