import React, { useState, useEffect } from 'react';
import { db, auth } from '../../firebase/config';
import './CompetitionReminders.css';

const CompetitionReminders = () => {
  const [contests, setContests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState('all');

  // Sample contest data (replace with API calls later)
  const sampleContests = [
    {
      id: 1,
      name: 'Weekly Contest 379',
      platform: 'LeetCode',
      date: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000), // 2 days from now
      duration: '1.5 hours',
      difficulty: 'Medium',
      url: 'https://leetcode.com/contest/',
    },
    {
      id: 2,
      name: 'Codeforces Round #918',
      platform: 'Codeforces',
      date: new Date(Date.now() + 5 * 24 * 60 * 60 * 1000), // 5 days from now
      duration: '2 hours',
      difficulty: 'All Levels',
      url: 'https://codeforces.com/contests',
    },
    {
      id: 3,
      name: 'CodeChef Starters 114',
      platform: 'CodeChef',
      date: new Date(Date.now() + 3 * 24 * 60 * 60 * 1000), // 3 days from now
      duration: '3 hours',
      difficulty: 'All Levels',
      url: 'https://www.codechef.com/contests',
    },
    {
      id: 4,
      name: 'Biweekly Contest 120',
      platform: 'LeetCode',
      date: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), // 7 days from now
      duration: '1.5 hours',
      difficulty: 'Medium',
      url: 'https://leetcode.com/contest/',
    },
    {
      id: 5,
      name: 'Educational Codeforces Round 160',
      platform: 'Codeforces',
      date: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000), // 10 days from now
      duration: '2 hours',
      difficulty: 'Educational',
      url: 'https://codeforces.com/contests',
    }
  ];

  useEffect(() => {
    fetchContests();
  }, [selectedPlatform]);

  const fetchContests = async () => {
    try {
      setLoading(true);
      setError(null);

      // Filter contests that are in the future
      const now = new Date();
      let filteredContests = sampleContests.filter(contest => contest.date > now);

      // Filter by platform if selected
      if (selectedPlatform !== 'all') {
        filteredContests = filteredContests.filter(
          contest => contest.platform.toLowerCase() === selectedPlatform.toLowerCase()
        );
      }

      // Sort by date (closest first)
      filteredContests.sort((a, b) => a.date - b.date);

      setContests(filteredContests);
    } catch (err) {
      console.error('Error fetching contests:', err);
      setError('Failed to load contest data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date) => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short'
    };
    return date.toLocaleDateString('en-US', options);
  };

  const getTimeUntil = (date) => {
    const now = new Date();
    const diffTime = Math.abs(date - now);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    
    if (diffDays > 0) {
      return `${diffDays} day${diffDays > 1 ? 's' : ''} ${diffHours} hour${diffHours > 1 ? 's' : ''} left`;
    }
    return `${diffHours} hour${diffHours > 1 ? 's' : ''} left`;
  };

  if (loading) {
    return (
      <div className="contests-container">
        <div className="loading">Loading contest data...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="contests-container">
        <div className="error">{error}</div>
        <button onClick={fetchContests} className="retry-button">
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="contests-container">
      <div className="contests-header">
        <h2>Upcoming Coding Contests</h2>
        <select
          value={selectedPlatform}
          onChange={(e) => setSelectedPlatform(e.target.value)}
          className="platform-select"
        >
          <option value="all">All Platforms</option>
          <option value="leetcode">LeetCode</option>
          <option value="codeforces">Codeforces</option>
          <option value="codechef">CodeChef</option>
        </select>
      </div>

      {contests.length === 0 ? (
        <div className="no-contests">
          No upcoming contests found for the selected platform.
        </div>
      ) : (
        <div className="contests-grid">
          {contests.map(contest => (
            <div key={contest.id} className="contest-card">
              <div className={`contest-platform ${contest.platform.toLowerCase()}`}>
                {contest.platform}
              </div>
              <h3 className="contest-name">{contest.name}</h3>
              <div className="contest-time">
                <div className="contest-date">{formatDate(contest.date)}</div>
                <div className="time-until">{getTimeUntil(contest.date)}</div>
              </div>
              <div className="contest-details">
                <span className="contest-duration">
                  <i className="far fa-clock"></i> {contest.duration}
                </span>
                <span className="contest-difficulty">
                  <i className="fas fa-signal"></i> {contest.difficulty}
                </span>
              </div>
              <a
                href={contest.url}
                target="_blank"
                rel="noopener noreferrer"
                className="register-button"
              >
                Register Now
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CompetitionReminders;
