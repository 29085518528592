import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import Sidebar from '../Navigation/Sidebar';

const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Sidebar />
      <div className="main-content with-sidebar">
        <div className="page-container">
          {children}
        </div>
      </div>
    </>
  );
};

export default PrivateRoute;
