import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import UserProfile from './components/Profile/UserProfile';
import LearningPath from './components/Learning/LearningPath';
import ProgressTracking from './components/Progress/ProgressTracking';
import RecommendedProblems from './components/Recommendations/RecommendedProblems';
import CompetitionReminders from './components/Reminders/CompetitionReminders';
import LearningResources from './components/Resources/LearningResources';
import VideoResources from './components/Resources/VideoResources';
import Login from './components/Auth/Login';
import SignUp from './components/Auth/SignUp';
import ResetPassword from './components/Auth/ResetPassword';
import PrivateRoute from './components/Auth/PrivateRoute';
import ShareableProfile from './components/Profile/ShareableProfile';
import UpcomingContests from './components/Contests/UpcomingContests';
import './App.css';

function AppContent() {
  const { currentUser } = useAuth();

  return (
    <div className="app-container">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <UserProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/learning"
          element={
            <PrivateRoute>
              <LearningPath />
            </PrivateRoute>
          }
        />
        <Route
          path="/analysis"
          element={
            <PrivateRoute>
              <ProgressTracking />
            </PrivateRoute>
          }
        />
        <Route
          path="/recommendations"
          element={
            <PrivateRoute>
              <RecommendedProblems />
            </PrivateRoute>
          }
        />
        <Route
          path="/competitions"
          element={
            <PrivateRoute>
              <div>
                <UpcomingContests />
                <CompetitionReminders />
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/resources/videos"
          element={
            <PrivateRoute>
              <VideoResources />
            </PrivateRoute>
          }
        />
        <Route
          path="/resources"
          element={
            <PrivateRoute>
              <LearningResources />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <UserProfile />
            </PrivateRoute>
          }
        />
        <Route path="/share/:shareId" element={<ShareableProfile />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="app">
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </div>
    </Router>
  );
}

export default App;
