import React, { useState } from 'react';
import './LearningPath.css';

const LearningPath = () => {
  const [activeTab, setActiveTab] = useState('dsa');

  const dsaPath = [
    {
      title: "Foundation",
      duration: "4-6 weeks",
      topics: [
        {
          name: "Basic Programming Concepts",
          subtopics: ["Variables & Data Types", "Control Flow", "Functions", "Arrays & Strings"]
        },
        {
          name: "Time & Space Complexity",
          subtopics: ["Big O Notation", "Best/Average/Worst Cases", "Space Complexity"]
        },
        {
          name: "Basic Data Structures",
          subtopics: ["Arrays", "Strings", "Linked Lists", "Stacks", "Queues"]
        },
        {
          name: "Basic Algorithms",
          subtopics: ["Sorting (Bubble, Selection, Insertion)", "Linear Search", "Binary Search"]
        }
      ]
    },
    {
      title: "Intermediate",
      duration: "8-10 weeks",
      topics: [
        {
          name: "Advanced Data Structures",
          subtopics: ["Trees", "Binary Search Trees", "Heaps", "Hash Tables", "Graphs"]
        },
        {
          name: "Advanced Algorithms",
          subtopics: ["Merge Sort", "Quick Sort", "DFS", "BFS", "Dijkstra's Algorithm"]
        },
        {
          name: "Problem Solving Patterns",
          subtopics: ["Two Pointers", "Sliding Window", "Fast & Slow Pointers", "Binary Search"]
        },
        {
          name: "Dynamic Programming Basics",
          subtopics: ["Memoization", "Tabulation", "Basic DP Problems"]
        }
      ]
    },
    {
      title: "Advanced",
      duration: "10-12 weeks",
      topics: [
        {
          name: "Advanced Algorithm Techniques",
          subtopics: ["Advanced Dynamic Programming", "Greedy Algorithms", "Backtracking", "Divide & Conquer"]
        },
        {
          name: "Complex Data Structures",
          subtopics: ["AVL Trees", "Red-Black Trees", "Segment Trees", "Trie", "Disjoint Set"]
        },
        {
          name: "Advanced Graph Algorithms",
          subtopics: ["Bellman-Ford", "Floyd-Warshall", "Minimum Spanning Trees", "Network Flow"]
        },
        {
          name: "String Algorithms",
          subtopics: ["KMP", "Rabin-Karp", "Suffix Arrays", "Regular Expressions"]
        }
      ]
    }
  ];

  const systemDesignPath = [
    {
      title: "Fundamentals",
      duration: "4-6 weeks",
      topics: [
        {
          name: "Basic Concepts",
          subtopics: ["Client-Server Model", "Network Protocols", "Storage Types", "APIs"]
        },
        {
          name: "Scalability Basics",
          subtopics: ["Vertical vs Horizontal Scaling", "Load Balancing", "Caching", "CDN"]
        },
        {
          name: "Databases",
          subtopics: ["SQL vs NoSQL", "ACID Properties", "Basic Database Design", "Indexing"]
        },
        {
          name: "Communication",
          subtopics: ["HTTP/HTTPS", "TCP/UDP", "REST", "WebSockets"]
        }
      ]
    },
    {
      title: "Components & Microservices",
      duration: "6-8 weeks",
      topics: [
        {
          name: "Distributed Systems",
          subtopics: ["CAP Theorem", "Consistency Patterns", "Availability Patterns", "Microservices Architecture"]
        },
        {
          name: "System Components",
          subtopics: ["Message Queues", "Load Balancers", "Caching Systems", "API Gateways"]
        },
        {
          name: "Data Management",
          subtopics: ["Sharding", "Replication", "Consistency", "Data Partitioning"]
        },
        {
          name: "Monitoring & Security",
          subtopics: ["Logging", "Metrics", "Authentication", "Authorization"]
        }
      ]
    },
    {
      title: "Advanced Topics",
      duration: "8-10 weeks",
      topics: [
        {
          name: "High Availability",
          subtopics: ["Failover", "Replication", "Redundancy", "Disaster Recovery"]
        },
        {
          name: "Performance",
          subtopics: ["Performance Metrics", "Optimization Techniques", "Bottleneck Analysis", "Capacity Planning"]
        },
        {
          name: "Real-world Systems",
          subtopics: ["URL Shortener", "Social Network", "Video Streaming", "Payment Systems"]
        },
        {
          name: "Modern Architecture",
          subtopics: ["Serverless", "Containers", "Cloud Native", "Event-Driven Architecture"]
        }
      ]
    }
  ];

  return (
    <div className="learning-path-container">
      <div className="tabs">
        <button 
          className={`tab ${activeTab === 'dsa' ? 'active' : ''}`}
          onClick={() => setActiveTab('dsa')}
        >
          Data Structures & Algorithms
        </button>
        <button 
          className={`tab ${activeTab === 'system' ? 'active' : ''}`}
          onClick={() => setActiveTab('system')}
        >
          System Design
        </button>
      </div>

      <div className="path-content">
        {(activeTab === 'dsa' ? dsaPath : systemDesignPath).map((level, index) => (
          <div key={index} className="level-card">
            <div className="level-header">
              <h3>{level.title}</h3>
              <span className="duration">{level.duration}</span>
            </div>
            <div className="topics-list">
              {level.topics.map((topic, topicIndex) => (
                <div key={topicIndex} className="topic">
                  <h4>{topic.name}</h4>
                  <ul>
                    {topic.subtopics.map((subtopic, subtopicIndex) => (
                      <li key={subtopicIndex}>{subtopic}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LearningPath;
