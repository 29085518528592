// Video resources data with proper categorization
const VIDEO_RESOURCES = [
  // Arrays & Hashing
  {
    id: 'v1',
    title: 'Arrays and Hashing - Complete Course',
    platform: 'YouTube',
    channel: 'NeetCode',
    url: 'https://www.youtube.com/watch?v=zLcNwcR6yxQ',
    duration: '2:15:30',
    difficulty: 'Medium',
    topic: 'Arrays & Hashing',
    description: 'Complete guide to array manipulation and hashing techniques',
    tags: ['arrays', 'hashing', 'data structures']
  },
  {
    id: 'v2',
    title: 'HashMap Implementation from Scratch',
    platform: 'YouTube',
    channel: 'Tech Dose',
    url: 'https://www.youtube.com/watch?v=7mUZ_1p_FcM',
    duration: '45:20',
    difficulty: 'Hard',
    topic: 'Arrays & Hashing',
    description: 'Learn to implement a HashMap from scratch',
    tags: ['hashmap', 'implementation', 'data structures']
  },
  {
    id: 'v3',
    title: 'Two Pointers Technique Mastery',
    platform: 'YouTube',
    channel: 'NeetCode',
    url: 'https://www.youtube.com/watch?v=LGQdb_M8kW0',
    duration: '1:30:00',
    difficulty: 'Medium',
    topic: 'Arrays & Hashing',
    description: 'Master the two pointers technique for array problems',
    tags: ['arrays', 'two pointers', 'algorithms']
  },
  {
    id: 'v4',
    title: 'Sliding Window Patterns',
    platform: 'YouTube',
    channel: 'Take U Forward',
    url: 'https://www.youtube.com/watch?v=EHCGAZBbB88',
    duration: '2:00:00',
    difficulty: 'Hard',
    topic: 'Arrays & Hashing',
    description: 'Advanced sliding window techniques',
    tags: ['arrays', 'sliding window', 'algorithms']
  },

  // Dynamic Programming
  {
    id: 'v5',
    title: 'Dynamic Programming Patterns',
    platform: 'YouTube',
    channel: 'Algorithms Made Easy',
    url: 'https://www.youtube.com/watch?v=oBt53YbR9Kk',
    duration: '4:20:00',
    difficulty: 'Hard',
    topic: 'Dynamic Programming',
    description: 'Master common DP patterns with examples',
    tags: ['dp', 'algorithms', 'patterns']
  },
  {
    id: 'v6',
    title: '0/1 Knapsack - Complete Guide',
    platform: 'YouTube',
    channel: 'Take U Forward',
    url: 'https://www.youtube.com/watch?v=GqOmJHQZivw',
    duration: '1:15:00',
    difficulty: 'Medium',
    topic: 'Dynamic Programming',
    description: 'Comprehensive guide to 0/1 Knapsack problem',
    tags: ['dp', 'knapsack', 'algorithms']
  },
  {
    id: 'v7',
    title: 'LCS Variations Explained',
    platform: 'YouTube',
    channel: 'Abdul Bari',
    url: 'https://www.youtube.com/watch?v=ASoaQq66foQ',
    duration: '2:30:00',
    difficulty: 'Hard',
    topic: 'Dynamic Programming',
    description: 'All variations of Longest Common Subsequence',
    tags: ['dp', 'lcs', 'strings']
  },
  {
    id: 'v8',
    title: 'Matrix Chain Multiplication',
    platform: 'YouTube',
    channel: 'TECH DOSE',
    url: 'https://www.youtube.com/watch?v=prx1psByp7U',
    duration: '1:45:00',
    difficulty: 'Hard',
    topic: 'Dynamic Programming',
    description: 'Deep dive into Matrix Chain Multiplication',
    tags: ['dp', 'matrix', 'optimization']
  },

  // Trees
  {
    id: 'v9',
    title: 'Binary Trees for Beginners',
    platform: 'YouTube',
    channel: 'mycodeschool',
    url: 'https://www.youtube.com/watch?v=H5JubkIy_p8',
    duration: '1:30:00',
    difficulty: 'Easy',
    topic: 'Trees',
    description: 'Introduction to Binary Trees and basic operations',
    tags: ['trees', 'binary trees', 'data structures']
  },
  {
    id: 'v10',
    title: 'Advanced Tree Algorithms',
    platform: 'YouTube',
    channel: 'Abdul Bari',
    url: 'https://www.youtube.com/watch?v=suj1ro8TIVY',
    duration: '2:45:00',
    difficulty: 'Hard',
    topic: 'Trees',
    description: 'Advanced tree algorithms and techniques',
    tags: ['trees', 'algorithms', 'advanced']
  },
  {
    id: 'v11',
    title: 'Red-Black Trees Explained',
    platform: 'YouTube',
    channel: 'MIT OpenCourseWare',
    url: 'https://www.youtube.com/watch?v=qvZGUFHWChY',
    duration: '1:20:00',
    difficulty: 'Hard',
    topic: 'Trees',
    description: 'Complete guide to Red-Black Trees',
    tags: ['trees', 'balanced trees', 'advanced']
  },
  {
    id: 'v12',
    title: 'AVL Trees Made Simple',
    platform: 'YouTube',
    channel: 'Jenny\'s Lectures',
    url: 'https://www.youtube.com/watch?v=jDM6_TnYIqE',
    duration: '1:00:00',
    difficulty: 'Medium',
    topic: 'Trees',
    description: 'Understanding AVL Tree rotations and balance',
    tags: ['trees', 'avl', 'balancing']
  },

  // Graphs
  {
    id: 'v13',
    title: 'Graph Theory Algorithms',
    platform: 'YouTube',
    channel: 'William Fiset',
    url: 'https://www.youtube.com/watch?v=09_LlHjoEiY',
    duration: '7:00:00',
    difficulty: 'Medium',
    topic: 'Graphs',
    description: 'Complete guide to graph algorithms',
    tags: ['graphs', 'algorithms', 'theory']
  },
  {
    id: 'v14',
    title: 'Shortest Path Algorithms',
    platform: 'YouTube',
    channel: 'Abdul Bari',
    url: 'https://www.youtube.com/watch?v=XB4MIexjvY0',
    duration: '1:45:00',
    difficulty: 'Hard',
    topic: 'Graphs',
    description: 'Dijkstra and Bellman-Ford algorithms',
    tags: ['graphs', 'shortest path', 'algorithms']
  },
  {
    id: 'v15',
    title: 'Network Flow & Max Flow',
    platform: 'YouTube',
    channel: 'MIT OpenCourseWare',
    url: 'https://www.youtube.com/watch?v=VYZGlgzr_As',
    duration: '2:15:00',
    difficulty: 'Hard',
    topic: 'Graphs',
    description: 'Understanding network flow problems',
    tags: ['graphs', 'network flow', 'advanced']
  },
  {
    id: 'v16',
    title: 'Minimum Spanning Trees',
    platform: 'YouTube',
    channel: 'TECH DOSE',
    url: 'https://www.youtube.com/watch?v=4ZlRH0eK-qQ',
    duration: '1:30:00',
    difficulty: 'Medium',
    topic: 'Graphs',
    description: 'Kruskal and Prim\'s algorithms explained',
    tags: ['graphs', 'mst', 'algorithms']
  },

  // String Algorithms
  {
    id: 'v17',
    title: 'KMP Algorithm Deep Dive',
    platform: 'YouTube',
    channel: 'Back To Back SWE',
    url: 'https://www.youtube.com/watch?v=BXCEFAzhxGY',
    duration: '1:00:00',
    difficulty: 'Hard',
    topic: 'String Algorithms',
    description: 'Detailed explanation of KMP pattern matching',
    tags: ['strings', 'pattern matching', 'kmp']
  },
  {
    id: 'v18',
    title: 'Rabin-Karp Algorithm',
    platform: 'YouTube',
    channel: 'Abdul Bari',
    url: 'https://www.youtube.com/watch?v=H4VrKHVG5qI',
    duration: '45:00',
    difficulty: 'Medium',
    topic: 'String Algorithms',
    description: 'Understanding rolling hash in Rabin-Karp',
    tags: ['strings', 'pattern matching', 'hashing']
  },
  {
    id: 'v19',
    title: 'Suffix Arrays and Trees',
    platform: 'YouTube',
    channel: 'TECH DOSE',
    url: 'https://www.youtube.com/watch?v=zqKlL3ZpTqs',
    duration: '2:00:00',
    difficulty: 'Hard',
    topic: 'String Algorithms',
    description: 'Advanced string data structures',
    tags: ['strings', 'suffix arrays', 'advanced']
  },
  {
    id: 'v20',
    title: 'String Manipulation Basics',
    platform: 'YouTube',
    channel: 'Programming with Mosh',
    url: 'https://www.youtube.com/watch?v=W8vRK_TBwlY',
    duration: '1:15:00',
    difficulty: 'Easy',
    topic: 'String Algorithms',
    description: 'Fundamental string operations and techniques',
    tags: ['strings', 'basics', 'manipulation']
  },

  // Recursion & Backtracking
  {
    id: 'v21',
    title: 'Recursion Masterclass',
    platform: 'YouTube',
    channel: 'Aditya Verma',
    url: 'https://www.youtube.com/watch?v=kHi1DUhp9kM',
    duration: '3:30:00',
    difficulty: 'Medium',
    topic: 'Recursion',
    description: 'Master recursion with systematic approach',
    tags: ['recursion', 'algorithms', 'problem solving']
  },
  {
    id: 'v22',
    title: 'Backtracking Algorithms',
    platform: 'YouTube',
    channel: 'Take U Forward',
    url: 'https://www.youtube.com/watch?v=DKCbsiDBN6c',
    duration: '2:10:00',
    difficulty: 'Hard',
    topic: 'Recursion',
    description: 'Complete guide to backtracking problems',
    tags: ['backtracking', 'recursion', 'algorithms']
  },
  {
    id: 'v23',
    title: 'N-Queens Problem Explained',
    platform: 'YouTube',
    channel: 'NeetCode',
    url: 'https://www.youtube.com/watch?v=Ph95IHmRp5M',
    duration: '30:00',
    difficulty: 'Hard',
    topic: 'Recursion',
    description: 'Solving N-Queens using backtracking',
    tags: ['backtracking', 'n-queens', 'algorithms']
  },
  {
    id: 'v24',
    title: 'Sudoku Solver Implementation',
    platform: 'YouTube',
    channel: 'Tech With Tim',
    url: 'https://www.youtube.com/watch?v=eqUwSA0xI-s',
    duration: '45:00',
    difficulty: 'Medium',
    topic: 'Recursion',
    description: 'Building a Sudoku solver using backtracking',
    tags: ['backtracking', 'sudoku', 'implementation']
  },

  // Sorting & Searching
  {
    id: 'v25',
    title: 'Sorting Algorithms Visualized',
    platform: 'YouTube',
    channel: 'CS Dojo',
    url: 'https://www.youtube.com/watch?v=kPRA0W1kECg',
    duration: '1:00:00',
    difficulty: 'Easy',
    topic: 'Sorting',
    description: 'Visual guide to sorting algorithms',
    tags: ['sorting', 'algorithms', 'visualization']
  },
  {
    id: 'v26',
    title: 'QuickSort In-Depth',
    platform: 'YouTube',
    channel: 'Abdul Bari',
    url: 'https://www.youtube.com/watch?v=7h1s2SojIRw',
    duration: '1:30:00',
    difficulty: 'Medium',
    topic: 'Sorting',
    description: 'Understanding QuickSort algorithm',
    tags: ['sorting', 'quicksort', 'algorithms']
  },
  {
    id: 'v27',
    title: 'Merge Sort Tutorial',
    platform: 'YouTube',
    channel: 'mycodeschool',
    url: 'https://www.youtube.com/watch?v=TzeBrDU-JaY',
    duration: '45:00',
    difficulty: 'Medium',
    topic: 'Sorting',
    description: 'Complete guide to Merge Sort',
    tags: ['sorting', 'merge sort', 'algorithms']
  },
  {
    id: 'v28',
    title: 'Binary Search Advanced Patterns',
    platform: 'YouTube',
    channel: 'NeetCode',
    url: 'https://www.youtube.com/watch?v=W9QJ8HaRvJQ',
    duration: '1:45:00',
    difficulty: 'Medium',
    topic: 'Searching',
    description: 'Advanced binary search techniques',
    tags: ['binary search', 'algorithms', 'patterns']
  },

  // Bit Manipulation
  {
    id: 'v29',
    title: 'Bit Manipulation Basics',
    platform: 'YouTube',
    channel: 'Tech Dose',
    url: 'https://www.youtube.com/watch?v=5rtVTYAk9KQ',
    duration: '1:00:00',
    difficulty: 'Medium',
    topic: 'Bit Manipulation',
    description: 'Introduction to bit manipulation techniques',
    tags: ['bits', 'manipulation', 'basics']
  },
  {
    id: 'v30',
    title: 'Advanced Bit Operations',
    platform: 'YouTube',
    channel: 'Take U Forward',
    url: 'https://www.youtube.com/watch?v=qGq9qVe8uR4',
    duration: '1:30:00',
    difficulty: 'Hard',
    topic: 'Bit Manipulation',
    description: 'Complex bit manipulation problems',
    tags: ['bits', 'advanced', 'algorithms']
  },

  // System Design
  {
    id: 'v31',
    title: 'System Design Fundamentals',
    platform: 'YouTube',
    channel: 'Gaurav Sen',
    url: 'https://www.youtube.com/watch?v=xpDnVSmNFX0',
    duration: '2:00:00',
    difficulty: 'Medium',
    topic: 'System Design',
    description: 'Basic concepts of system design',
    tags: ['system design', 'architecture', 'basics']
  },
  {
    id: 'v32',
    title: 'Designing Instagram',
    platform: 'YouTube',
    channel: 'Tech Dummies',
    url: 'https://www.youtube.com/watch?v=QmX2NPkJTKg',
    duration: '1:30:00',
    difficulty: 'Hard',
    topic: 'System Design',
    description: 'System design case study of Instagram',
    tags: ['system design', 'case study', 'scalability']
  },

  // Object-Oriented Programming
  {
    id: 'v33',
    title: 'OOP Concepts',
    platform: 'YouTube',
    channel: 'Programming with Mosh',
    url: 'https://www.youtube.com/watch?v=pTB0EiLXUC8',
    duration: '2:00:00',
    difficulty: 'Easy',
    topic: 'Object-Oriented Programming',
    description: 'Complete guide to OOP concepts',
    tags: ['oop', 'basics', 'programming']
  },
  {
    id: 'v34',
    title: 'Design Patterns',
    platform: 'YouTube',
    channel: 'Christopher Okhravi',
    url: 'https://www.youtube.com/watch?v=v9ejT8FO-7I',
    duration: '3:00:00',
    difficulty: 'Hard',
    topic: 'Object-Oriented Programming',
    description: 'Common design patterns explained',
    tags: ['design patterns', 'oop', 'advanced']
  },

  // Additional Arrays & Hashing Videos
  {
    id: 'v35',
    title: 'Modern Array Methods in JavaScript',
    platform: 'YouTube',
    channel: 'Web Dev Simplified',
    url: 'https://www.youtube.com/watch?v=R8rmfD9Y5-c',
    duration: '1:15:00',
    difficulty: 'Easy',
    topic: 'Arrays & Hashing',
    description: '2024 guide to modern array methods and best practices',
    tags: ['javascript', 'arrays', 'modern']
  },
  {
    id: 'v36',
    title: 'Advanced HashMap Techniques',
    platform: 'YouTube',
    channel: 'ByteByteGo',
    url: 'https://www.youtube.com/watch?v=K6WGRBhacq8',
    duration: '45:00',
    difficulty: 'Hard',
    topic: 'Arrays & Hashing',
    description: 'Latest HashMap optimization techniques for 2024',
    tags: ['hashmap', 'optimization', 'advanced']
  },

  // Latest Dynamic Programming Content
  {
    id: 'v37',
    title: 'DP with Strings - 2024 Guide',
    platform: 'YouTube',
    channel: 'Algorithms Made Easy',
    url: 'https://www.youtube.com/watch?v=XYi2-LPrwm4',
    duration: '2:00:00',
    difficulty: 'Medium',
    topic: 'Dynamic Programming',
    description: 'Modern approaches to string-based DP problems',
    tags: ['dp', 'strings', 'modern']
  },
  {
    id: 'v38',
    title: 'State Reduction in DP',
    platform: 'YouTube',
    channel: 'William Lin',
    url: 'https://www.youtube.com/watch?v=5WZl3MMT0Eg',
    duration: '1:30:00',
    difficulty: 'Hard',
    topic: 'Dynamic Programming',
    description: 'Advanced state reduction techniques in DP',
    tags: ['dp', 'optimization', 'state reduction']
  },

  // Modern System Design
  {
    id: 'v39',
    title: 'Microservices in 2024',
    platform: 'YouTube',
    channel: 'TechWorld with Nana',
    url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
    duration: '2:30:00',
    difficulty: 'Medium',
    topic: 'System Design',
    description: 'Modern microservices architecture and patterns',
    tags: ['microservices', 'architecture', '2024']
  },
  {
    id: 'v40',
    title: 'Distributed Systems Deep Dive',
    platform: 'YouTube',
    channel: 'Martin Kleppmann',
    url: 'https://www.youtube.com/watch?v=UEAMfLPZZhE',
    duration: '3:00:00',
    difficulty: 'Hard',
    topic: 'System Design',
    description: 'Latest trends in distributed systems design',
    tags: ['distributed systems', 'scalability', 'modern']
  },

  // Cloud Native Development
  {
    id: 'v41',
    title: 'Kubernetes Best Practices 2024',
    platform: 'YouTube',
    channel: 'TechWorld with Nana',
    url: 'https://www.youtube.com/watch?v=X48VuDVv0do',
    duration: '2:00:00',
    difficulty: 'Medium',
    topic: 'Cloud Computing',
    description: 'Latest Kubernetes patterns and practices',
    tags: ['kubernetes', 'devops', 'cloud']
  },
  {
    id: 'v42',
    title: 'AWS Services Deep Dive',
    platform: 'YouTube',
    channel: 'AWS Events',
    url: 'https://www.youtube.com/watch?v=JIbIYCM48to',
    duration: '3:00:00',
    difficulty: 'Hard',
    topic: 'Cloud Computing',
    description: 'Comprehensive guide to AWS services in 2024',
    tags: ['aws', 'cloud', 'services']
  },

  // Machine Learning Algorithms
  {
    id: 'v43',
    title: 'ML Algorithms for Coding Interviews',
    platform: 'YouTube',
    channel: 'Krish Naik',
    url: 'https://www.youtube.com/watch?v=gmvvaobm7eQ',
    duration: '2:30:00',
    difficulty: 'Hard',
    topic: 'Machine Learning',
    description: 'Essential ML algorithms for technical interviews',
    tags: ['machine learning', 'algorithms', 'interviews']
  },
  {
    id: 'v44',
    title: 'Neural Networks from Scratch',
    platform: 'YouTube',
    channel: 'Sentdex',
    url: 'https://www.youtube.com/watch?v=Wo5dMEP_BbI',
    duration: '4:00:00',
    difficulty: 'Hard',
    topic: 'Machine Learning',
    description: 'Building neural networks from ground up',
    tags: ['neural networks', 'deep learning', 'python']
  },

  // Web Development
  {
    id: 'v45',
    title: 'Modern React Patterns 2024',
    platform: 'YouTube',
    channel: 'Codevolution',
    url: 'https://www.youtube.com/watch?v=bMknfKXIFA8',
    duration: '2:00:00',
    difficulty: 'Medium',
    topic: 'Web Development',
    description: 'Latest React patterns and best practices',
    tags: ['react', 'frontend', 'modern']
  },
  {
    id: 'v46',
    title: 'Full Stack Development 2024',
    platform: 'YouTube',
    channel: 'Traversy Media',
    url: 'https://www.youtube.com/watch?v=nu_pCVPKzTk',
    duration: '3:30:00',
    difficulty: 'Medium',
    topic: 'Web Development',
    description: 'Modern full stack development guide',
    tags: ['fullstack', 'web', 'modern']
  },

  // Mobile Development
  {
    id: 'v47',
    title: 'Flutter in 2024',
    platform: 'YouTube',
    channel: 'Flutter',
    url: 'https://www.youtube.com/watch?v=pTJJsmejUOQ',
    duration: '2:30:00',
    difficulty: 'Medium',
    topic: 'Mobile Development',
    description: 'Latest Flutter features and patterns',
    tags: ['flutter', 'mobile', 'cross-platform']
  },
  {
    id: 'v48',
    title: 'iOS Development with SwiftUI',
    platform: 'YouTube',
    channel: 'Paul Hudson',
    url: 'https://www.youtube.com/watch?v=F2ojC6TNwws',
    duration: '3:00:00',
    difficulty: 'Medium',
    topic: 'Mobile Development',
    description: 'Modern iOS development using SwiftUI',
    tags: ['ios', 'swift', 'mobile']
  },

  // Database Design
  {
    id: 'v49',
    title: 'MongoDB Best Practices 2024',
    platform: 'YouTube',
    channel: 'MongoDB',
    url: 'https://www.youtube.com/watch?v=pWbMrx5rVBE',
    duration: '2:00:00',
    difficulty: 'Medium',
    topic: 'Databases',
    description: 'Latest MongoDB patterns and optimization',
    tags: ['mongodb', 'nosql', 'database']
  },
  {
    id: 'v50',
    title: 'PostgreSQL Advanced Features',
    platform: 'YouTube',
    channel: 'EnterpriseDB',
    url: 'https://www.youtube.com/watch?v=qw--VYLpxG4',
    duration: '2:30:00',
    difficulty: 'Hard',
    topic: 'Databases',
    description: 'Advanced PostgreSQL features and optimization',
    tags: ['postgresql', 'sql', 'advanced']
  }
];

// Available topics for filtering
export const TOPICS = [
  'Arrays & Hashing',
  'Dynamic Programming',
  'Trees',
  'Graphs',
  'String Algorithms',
  'Recursion',
  'Sorting',
  'Searching',
  'Bit Manipulation',
  'System Design',
  'Object-Oriented Programming',
  'Cloud Computing',
  'Machine Learning',
  'Web Development',
  'Mobile Development',
  'Databases'
];

// Available difficulty levels
export const DIFFICULTY_LEVELS = ['Easy', 'Medium', 'Hard'];

// Function to get all video resources
export const getAllVideoResources = () => {
  return VIDEO_RESOURCES;
};

// Function to get videos by topic
export const getVideosByTopic = (topic) => {
  return VIDEO_RESOURCES.filter(video => video.topic === topic);
};

// Function to get videos by difficulty
export const getVideosByDifficulty = (difficulty) => {
  return VIDEO_RESOURCES.filter(video => video.difficulty === difficulty);
};

// Function to get videos by topic and difficulty
export const getFilteredVideos = (filters) => {
  return VIDEO_RESOURCES.filter(video => {
    if (filters.topic && filters.topic !== 'all' && video.topic !== filters.topic) {
      return false;
    }
    if (filters.difficulty && filters.difficulty !== 'all' && video.difficulty !== filters.difficulty) {
      return false;
    }
    if (filters.searchQuery) {
      const query = filters.searchQuery.toLowerCase();
      return (
        video.title.toLowerCase().includes(query) ||
        video.description.toLowerCase().includes(query) ||
        video.tags.some(tag => tag.toLowerCase().includes(query))
      );
    }
    return true;
  });
};

// Function to get video by ID
export const getVideoById = (id) => {
  return VIDEO_RESOURCES.find(video => video.id === id);
};
