import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics, isSupported } from 'firebase/analytics';
import prodConfig from './config.prod';

// Use production config if in production environment or environment variables are not set
const firebaseConfig = process.env.NODE_ENV === 'production' ? prodConfig : {
  apiKey: "AIzaSyCuF0N2QTQnX_OGEPTojWSekY5xB7hGJ_c",
  authDomain: "codecracker-9eaf0.firebaseapp.com",
  projectId: "codecracker-9eaf0",
  storageBucket: "codecracker-9eaf0.appspot.com",
  messagingSenderId: "1048380917183",
  appId: "1:1048380917183:web:a7b66c0349e8c74ed34d0a",
  measurementId: "G-M9C4LD6GR7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Initialize Analytics only in production
let analytics = null;
if (process.env.NODE_ENV === 'production') {
  isSupported().then((supported) => {
    if (supported) {
      analytics = getAnalytics(app);
      console.log('Analytics initialized in production');
    }
  });
}

export { app, auth, db, analytics };
