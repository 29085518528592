import axios from 'axios';

const LEETCODE_API = 'https://leetcode.com/api/problems/all/';
const CODEFORCES_API = 'https://codeforces.com/api/problemset.problems';
const CODECHEF_API = 'https://www.codechef.com/api/list/contests/all'; // Note: CodeChef API might require authentication

// Sample problems array containing all problems
const SAMPLE_PROBLEMS = [
  // LeetCode Easy Problems
  {
    id: 'leetcode_1',
    title: 'Two Sum',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/two-sum/',
    tags: ['Arrays & Hashing']
  },
  {
    id: 'leetcode_2',
    title: 'Valid Parentheses',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/valid-parentheses/',
    tags: ['Stack', 'String Algorithms']
  },
  {
    id: 'leetcode_3',
    title: 'Merge Two Sorted Lists',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/merge-two-sorted-lists/',
    tags: ['Linked List', 'Two Pointers']
  },
  {
    id: 'leetcode_4',
    title: 'Best Time to Buy and Sell Stock',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/best-time-to-buy-and-sell-stock/',
    tags: ['Arrays & Hashing', 'Dynamic Programming']
  },
  {
    id: 'leetcode_5',
    title: 'Valid Palindrome',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/valid-palindrome/',
    tags: ['Two Pointers', 'String Algorithms']
  },
  {
    id: 'leetcode_6',
    title: 'Invert Binary Tree',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/invert-binary-tree/',
    tags: ['Trees', 'Binary Search']
  },
  {
    id: 'leetcode_7',
    title: 'Valid Anagram',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/valid-anagram/',
    tags: ['Arrays & Hashing', 'String Algorithms']
  },
  {
    id: 'leetcode_8',
    title: 'Binary Search',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/binary-search/',
    tags: ['Binary Search', 'Arrays & Hashing']
  },
  {
    id: 'leetcode_9',
    title: 'Flood Fill',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/flood-fill/',
    tags: ['Graphs', 'Implementation']
  },
  {
    id: 'leetcode_10',
    title: 'Lowest Common Ancestor of a Binary Search Tree',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/lowest-common-ancestor-of-a-binary-search-tree/',
    tags: ['Trees', 'Binary Search']
  },
  {
    id: 'leetcode_11',
    title: 'Balanced Binary Tree',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/balanced-binary-tree/',
    tags: ['Trees', 'Implementation']
  },
  {
    id: 'leetcode_12',
    title: 'Linked List Cycle',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/linked-list-cycle/',
    tags: ['Linked List', 'Two Pointers']
  },
  {
    id: 'leetcode_13',
    title: 'Implement Queue using Stacks',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/implement-queue-using-stacks/',
    tags: ['Stack', 'Implementation']
  },
  {
    id: 'leetcode_14',
    title: 'First Bad Version',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/first-bad-version/',
    tags: ['Binary Search', 'Implementation']
  },
  {
    id: 'leetcode_15',
    title: 'Ransom Note',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/ransom-note/',
    tags: ['Arrays & Hashing', 'String Algorithms']
  },
  {
    id: 'leetcode_16',
    title: 'Climbing Stairs',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/climbing-stairs/',
    tags: ['Dynamic Programming', 'Math & Geometry']
  },
  {
    id: 'leetcode_17',
    title: 'Maximum Depth of Binary Tree',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/maximum-depth-of-binary-tree/',
    tags: ['Trees', 'Implementation']
  },
  {
    id: 'leetcode_18',
    title: 'Contains Duplicate',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/contains-duplicate/',
    tags: ['Arrays & Hashing', 'Implementation']
  },
  {
    id: 'leetcode_19',
    title: 'Same Tree',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/same-tree/',
    tags: ['Trees', 'Implementation']
  },
  {
    id: 'leetcode_20',
    title: 'Path Sum',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/path-sum/',
    tags: ['Trees', 'Implementation']
  },

  // LeetCode Medium Problems
  {
    id: 'leetcode_21',
    title: '3Sum',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/3sum/',
    tags: ['Arrays & Hashing', 'Two Pointers']
  },
  {
    id: 'leetcode_22',
    title: 'Binary Tree Level Order Traversal',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/binary-tree-level-order-traversal/',
    tags: ['Trees', 'Implementation']
  },
  {
    id: 'leetcode_23',
    title: 'Clone Graph',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/clone-graph/',
    tags: ['Graphs', 'Implementation']
  },
  {
    id: 'leetcode_24',
    title: 'Course Schedule',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/course-schedule/',
    tags: ['Graphs', 'Implementation']
  },
  {
    id: 'leetcode_25',
    title: 'House Robber',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/house-robber/',
    tags: ['Dynamic Programming', 'Arrays & Hashing']
  },
  {
    id: 'leetcode_26',
    title: 'Longest Palindromic Substring',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/longest-palindromic-substring/',
    tags: ['String Algorithms', 'Dynamic Programming']
  },
  {
    id: 'leetcode_27',
    title: 'Container With Most Water',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/container-with-most-water/',
    tags: ['Two Pointers', 'Arrays & Hashing']
  },
  {
    id: 'leetcode_28',
    title: 'Letter Combinations of a Phone Number',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/letter-combinations-of-a-phone-number/',
    tags: ['String Algorithms', 'Implementation']
  },
  {
    id: 'leetcode_29',
    title: 'Generate Parentheses',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/generate-parentheses/',
    tags: ['String Algorithms', 'Implementation']
  },
  {
    id: 'leetcode_30',
    title: 'Search in Rotated Sorted Array',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/search-in-rotated-sorted-array/',
    tags: ['Binary Search', 'Arrays & Hashing']
  },

  // LeetCode Hard Problems
  {
    id: 'leetcode_31',
    title: 'Median of Two Sorted Arrays',
    platform: 'LeetCode',
    difficulty: 'hard',
    url: 'https://leetcode.com/problems/median-of-two-sorted-arrays/',
    tags: ['Binary Search', 'Arrays & Hashing']
  },
  {
    id: 'leetcode_32',
    title: 'Regular Expression Matching',
    platform: 'LeetCode',
    difficulty: 'hard',
    url: 'https://leetcode.com/problems/regular-expression-matching/',
    tags: ['Dynamic Programming', 'String Algorithms']
  },
  {
    id: 'leetcode_33',
    title: 'Merge k Sorted Lists',
    platform: 'LeetCode',
    difficulty: 'hard',
    url: 'https://leetcode.com/problems/merge-k-sorted-lists/',
    tags: ['Linked List', 'Implementation']
  },
  {
    id: 'leetcode_34',
    title: 'Trapping Rain Water',
    platform: 'LeetCode',
    difficulty: 'hard',
    url: 'https://leetcode.com/problems/trapping-rain-water/',
    tags: ['Two Pointers', 'Dynamic Programming']
  },
  {
    id: 'leetcode_35',
    title: 'Binary Tree Maximum Path Sum',
    platform: 'LeetCode',
    difficulty: 'hard',
    url: 'https://leetcode.com/problems/binary-tree-maximum-path-sum/',
    tags: ['Trees', 'Dynamic Programming']
  },

  // Additional LeetCode Medium Problems
  {
    id: 'leetcode_36',
    title: 'Rotate Array',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/rotate-array/',
    tags: ['Arrays & Hashing', 'Two Pointers']
  },
  {
    id: 'leetcode_37',
    title: 'Decode String',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/decode-string/',
    tags: ['Stack', 'String Algorithms']
  },
  {
    id: 'leetcode_38',
    title: 'Find All Anagrams in a String',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/find-all-anagrams-in-a-string/',
    tags: ['String Algorithms', 'Sliding Window']
  },
  {
    id: 'leetcode_39',
    title: 'Daily Temperatures',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/daily-temperatures/',
    tags: ['Stack', 'Arrays & Hashing']
  },
  {
    id: 'leetcode_40',
    title: 'Partition Equal Subset Sum',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/partition-equal-subset-sum/',
    tags: ['Dynamic Programming', 'Arrays & Hashing']
  },
  {
    id: 'leetcode_41',
    title: 'Target Sum',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/target-sum/',
    tags: ['Dynamic Programming', 'Arrays & Hashing']
  },
  {
    id: 'leetcode_42',
    title: 'Coin Change',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/coin-change/',
    tags: ['Dynamic Programming', 'Arrays & Hashing']
  },
  {
    id: 'leetcode_43',
    title: 'Longest Increasing Subsequence',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/longest-increasing-subsequence/',
    tags: ['Dynamic Programming', 'Binary Search']
  },
  {
    id: 'leetcode_44',
    title: 'Word Break',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/word-break/',
    tags: ['Dynamic Programming', 'String Algorithms']
  },
  {
    id: 'leetcode_45',
    title: 'Unique Paths',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/unique-paths/',
    tags: ['Dynamic Programming', 'Math & Geometry']
  },

  // Additional LeetCode Hard Problems
  {
    id: 'leetcode_46',
    title: 'Word Search II',
    platform: 'LeetCode',
    difficulty: 'hard',
    url: 'https://leetcode.com/problems/word-search-ii/',
    tags: ['Trie', 'Arrays & Hashing']
  },
  {
    id: 'leetcode_47',
    title: 'Longest Valid Parentheses',
    platform: 'LeetCode',
    difficulty: 'hard',
    url: 'https://leetcode.com/problems/longest-valid-parentheses/',
    tags: ['Dynamic Programming', 'Stack']
  },
  {
    id: 'leetcode_48',
    title: 'Best Time to Buy and Sell Stock III',
    platform: 'LeetCode',
    difficulty: 'hard',
    url: 'https://leetcode.com/problems/best-time-to-buy-and-sell-stock-iii/',
    tags: ['Dynamic Programming', 'Arrays & Hashing']
  },
  {
    id: 'leetcode_49',
    title: 'Longest Consecutive Sequence',
    platform: 'LeetCode',
    difficulty: 'hard',
    url: 'https://leetcode.com/problems/longest-consecutive-sequence/',
    tags: ['Arrays & Hashing', 'Dynamic Programming']
  },
  {
    id: 'leetcode_50',
    title: 'Maximum Profit in Job Scheduling',
    platform: 'LeetCode',
    difficulty: 'hard',
    url: 'https://leetcode.com/problems/maximum-profit-in-job-scheduling/',
    tags: ['Dynamic Programming', 'Binary Search']
  },

  // CodeForces Problems
  {
    id: 'codeforces_1',
    title: 'Watermelon',
    platform: 'CodeForces',
    difficulty: 'easy',
    url: 'https://codeforces.com/problemset/problem/4/A',
    tags: ['Math & Geometry', 'Implementation']
  },
  {
    id: 'codeforces_2',
    title: 'Way Too Long Words',
    platform: 'CodeForces',
    difficulty: 'easy',
    url: 'https://codeforces.com/problemset/problem/71/A',
    tags: ['String Algorithms', 'Implementation']
  },
  {
    id: 'codeforces_3',
    title: 'Team',
    platform: 'CodeForces',
    difficulty: 'easy',
    url: 'https://codeforces.com/problemset/problem/231/A',
    tags: ['Implementation', 'Greedy']
  },
  {
    id: 'codeforces_4',
    title: 'Theatre Square',
    platform: 'CodeForces',
    difficulty: 'medium',
    url: 'https://codeforces.com/problemset/problem/1/A',
    tags: ['Math & Geometry', 'Implementation']
  },
  {
    id: 'codeforces_5',
    title: 'String Task',
    platform: 'CodeForces',
    difficulty: 'medium',
    url: 'https://codeforces.com/problemset/problem/118/A',
    tags: ['String Algorithms', 'Implementation']
  },

  // More CodeForces Problems
  {
    id: 'codeforces_6',
    title: 'Lucky Division',
    platform: 'CodeForces',
    difficulty: 'medium',
    url: 'https://codeforces.com/problemset/problem/122/A',
    tags: ['Math & Geometry', 'Implementation']
  },
  {
    id: 'codeforces_7',
    title: 'System of Equations',
    platform: 'CodeForces',
    difficulty: 'medium',
    url: 'https://codeforces.com/problemset/problem/214/A',
    tags: ['Math & Geometry', 'Implementation']
  },
  {
    id: 'codeforces_8',
    title: 'Ice Skating',
    platform: 'CodeForces',
    difficulty: 'hard',
    url: 'https://codeforces.com/problemset/problem/217/A',
    tags: ['Graphs', 'Implementation']
  },
  {
    id: 'codeforces_9',
    title: 'Cosmic Tables',
    platform: 'CodeForces',
    difficulty: 'hard',
    url: 'https://codeforces.com/problemset/problem/222/B',
    tags: ['Implementation', 'Arrays & Hashing']
  },
  {
    id: 'codeforces_10',
    title: 'Beautiful Matrix',
    platform: 'CodeForces',
    difficulty: 'easy',
    url: 'https://codeforces.com/problemset/problem/263/A',
    tags: ['Implementation', 'Math & Geometry']
  },
  {
    id: 'codeforces_11',
    title: 'Queue at the School',
    platform: 'CodeForces',
    difficulty: 'easy',
    url: 'https://codeforces.com/problemset/problem/266/B',
    tags: ['Implementation', 'String Algorithms']
  },
  {
    id: 'codeforces_12',
    title: 'Beautiful Year',
    platform: 'CodeForces',
    difficulty: 'easy',
    url: 'https://codeforces.com/problemset/problem/271/A',
    tags: ['Implementation', 'Math & Geometry']
  },
  {
    id: 'codeforces_13',
    title: 'Lights Out',
    platform: 'CodeForces',
    difficulty: 'medium',
    url: 'https://codeforces.com/problemset/problem/275/A',
    tags: ['Implementation', 'Arrays & Hashing']
  },
  {
    id: 'codeforces_14',
    title: 'Word',
    platform: 'CodeForces',
    difficulty: 'easy',
    url: 'https://codeforces.com/problemset/problem/59/A',
    tags: ['Implementation', 'String Algorithms']
  },
  {
    id: 'codeforces_15',
    title: 'Word Capitalization',
    platform: 'CodeForces',
    difficulty: 'easy',
    url: 'https://codeforces.com/problemset/problem/281/A',
    tags: ['Implementation', 'String Algorithms']
  },

  // Additional CodeForces Problems
  {
    id: 'codeforces_16',
    title: 'Helpful Maths',
    platform: 'CodeForces',
    difficulty: 'easy',
    url: 'https://codeforces.com/problemset/problem/339/A',
    tags: ['Implementation', 'String Algorithms']
  },
  {
    id: 'codeforces_17',
    title: 'Petya and Strings',
    platform: 'CodeForces',
    difficulty: 'easy',
    url: 'https://codeforces.com/problemset/problem/112/A',
    tags: ['Implementation', 'String Algorithms']
  },
  {
    id: 'codeforces_18',
    title: 'Bit++',
    platform: 'CodeForces',
    difficulty: 'easy',
    url: 'https://codeforces.com/problemset/problem/282/A',
    tags: ['Implementation']
  },
  {
    id: 'codeforces_19',
    title: 'Young Physicist',
    platform: 'CodeForces',
    difficulty: 'easy',
    url: 'https://codeforces.com/problemset/problem/69/A',
    tags: ['Math & Geometry', 'Implementation']
  },
  {
    id: 'codeforces_20',
    title: 'Beautiful Matrix',
    platform: 'CodeForces',
    difficulty: 'easy',
    url: 'https://codeforces.com/problemset/problem/263/A',
    tags: ['Implementation', 'Math & Geometry']
  },

  // CodeChef Problems
  {
    id: 'codechef_1',
    title: 'Life, the Universe and Everything',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/TEST',
    tags: ['Implementation']
  },
  {
    id: 'codechef_2',
    title: 'ATM',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/HS08TEST',
    tags: ['Implementation', 'Math & Geometry']
  },
  {
    id: 'codechef_3',
    title: 'Enormous Input Test',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/INTEST',
    tags: ['Implementation']
  },
  {
    id: 'codechef_4',
    title: 'Factorial',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/FCTRL',
    tags: ['Math & Geometry', 'Implementation']
  },
  {
    id: 'codechef_5',
    title: 'Small Factorials',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/FCTRL2',
    tags: ['Math & Geometry', 'Implementation']
  },

  // More CodeChef Problems
  {
    id: 'codechef_6',
    title: 'Turbo Sort',
    platform: 'CodeChef',
    difficulty: 'medium',
    url: 'https://www.codechef.com/problems/TSORT',
    tags: ['Implementation', 'Arrays & Hashing']
  },
  {
    id: 'codechef_7',
    title: 'The Lead Game',
    platform: 'CodeChef',
    difficulty: 'medium',
    url: 'https://www.codechef.com/problems/TLG',
    tags: ['Implementation']
  },
  {
    id: 'codechef_8',
    title: 'Paying up',
    platform: 'CodeChef',
    difficulty: 'medium',
    url: 'https://www.codechef.com/problems/MARCHA1',
    tags: ['Dynamic Programming', 'Implementation']
  },
  {
    id: 'codechef_9',
    title: 'Racing Horses',
    platform: 'CodeChef',
    difficulty: 'medium',
    url: 'https://www.codechef.com/problems/HORSES',
    tags: ['Arrays & Hashing', 'Implementation']
  },
  {
    id: 'codechef_10',
    title: 'Cleaning Up',
    platform: 'CodeChef',
    difficulty: 'medium',
    url: 'https://www.codechef.com/problems/CLEANUP',
    tags: ['Implementation', 'Greedy']
  },
  {
    id: 'codechef_11',
    title: 'Ciel and A-B Problem',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/CIELAB',
    tags: ['Math & Geometry', 'Implementation']
  },
  {
    id: 'codechef_12',
    title: 'Uncle Johny',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/JOHNY',
    tags: ['Implementation', 'Arrays & Hashing']
  },
  {
    id: 'codechef_13',
    title: 'Chef and Operators',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/CHOPRT',
    tags: ['Implementation', 'Math & Geometry']
  },
  {
    id: 'codechef_14',
    title: 'Sum of Digits',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/FLOW006',
    tags: ['Implementation', 'Math & Geometry']
  },
  {
    id: 'codechef_15',
    title: 'First and Last Digit',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/FLOW004',
    tags: ['Implementation', 'Math & Geometry']
  },

  // Additional CodeChef Problems
  {
    id: 'codechef_16',
    title: 'Number Mirror',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/START01',
    tags: ['Implementation', 'Math & Geometry']
  },
  {
    id: 'codechef_17',
    title: 'Add Two Numbers',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/FLOW001',
    tags: ['Implementation', 'Math & Geometry']
  },
  {
    id: 'codechef_18',
    title: 'Find Remainder',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/FLOW002',
    tags: ['Implementation', 'Math & Geometry']
  },
  {
    id: 'codechef_19',
    title: 'Lucky Four',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/LUCKFOUR',
    tags: ['Implementation', 'Math & Geometry']
  },
  {
    id: 'codechef_20',
    title: 'Valid Triangles',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/FLOW013',
    tags: ['Implementation', 'Math & Geometry']
  },
  {
    id: 'codechef_21',
    title: 'Chef and Remissness',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/REMISS',
    tags: ['Implementation', 'Math & Geometry']
  },
  {
    id: 'codechef_22',
    title: 'Mahasena',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/AMR15A',
    tags: ['Implementation', 'Arrays & Hashing']
  },
  {
    id: 'codechef_23',
    title: 'Chef and Fruits',
    platform: 'CodeChef',
    difficulty: 'medium',
    url: 'https://www.codechef.com/problems/FRUITS',
    tags: ['Implementation', 'Greedy']
  },
  {
    id: 'codechef_24',
    title: 'Primality Test',
    platform: 'CodeChef',
    difficulty: 'medium',
    url: 'https://www.codechef.com/problems/PRB01',
    tags: ['Math & Geometry', 'Implementation']
  },
  {
    id: 'codechef_25',
    title: 'Chef and Rainbow Array',
    platform: 'CodeChef',
    difficulty: 'medium',
    url: 'https://www.codechef.com/problems/RAINBOWA',
    tags: ['Arrays & Hashing', 'Implementation']
  },

  // Additional LeetCode Easy Problems
  {
    id: 'leetcode_51',
    title: 'Palindrome Number',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/palindrome-number/',
    tags: ['Math & Geometry', 'Implementation']
  },
  {
    id: 'leetcode_52',
    title: 'Remove Element',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/remove-element/',
    tags: ['Arrays & Hashing', 'Two Pointers']
  },
  {
    id: 'leetcode_53',
    title: 'Length of Last Word',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/length-of-last-word/',
    tags: ['String Algorithms', 'Implementation']
  },
  {
    id: 'leetcode_54',
    title: 'Plus One',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/plus-one/',
    tags: ['Arrays & Hashing', 'Math & Geometry']
  },
  {
    id: 'leetcode_55',
    title: 'Add Binary',
    platform: 'LeetCode',
    difficulty: 'easy',
    url: 'https://leetcode.com/problems/add-binary/',
    tags: ['String Algorithms', 'Math & Geometry']
  },

  // Additional CodeForces Medium Problems
  {
    id: 'codeforces_21',
    title: 'Dragons',
    platform: 'CodeForces',
    difficulty: 'medium',
    url: 'https://codeforces.com/problemset/problem/230/A',
    tags: ['Greedy', 'Implementation']
  },
  {
    id: 'codeforces_22',
    title: 'Puzzles',
    platform: 'CodeForces',
    difficulty: 'medium',
    url: 'https://codeforces.com/problemset/problem/337/A',
    tags: ['Greedy', 'Implementation']
  },
  {
    id: 'codeforces_23',
    title: 'Chat room',
    platform: 'CodeForces',
    difficulty: 'medium',
    url: 'https://codeforces.com/problemset/problem/58/A',
    tags: ['String Algorithms', 'Implementation']
  },
  {
    id: 'codeforces_24',
    title: 'Airport',
    platform: 'CodeForces',
    difficulty: 'medium',
    url: 'https://codeforces.com/problemset/problem/218/B',
    tags: ['Implementation', 'Greedy']
  },
  {
    id: 'codeforces_25',
    title: 'DZY Loves Chessboard',
    platform: 'CodeForces',
    difficulty: 'medium',
    url: 'https://codeforces.com/problemset/problem/445/A',
    tags: ['Implementation', 'Math & Geometry']
  },

  // Final batch of LeetCode problems
  {
    id: 'leetcode_56',
    title: 'Subarray Sum Equals K',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/subarray-sum-equals-k/',
    tags: ['Arrays & Hashing', 'Prefix Sum']
  },
  {
    id: 'leetcode_57',
    title: 'Task Scheduler',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/task-scheduler/',
    tags: ['Arrays & Hashing', 'Greedy']
  },
  {
    id: 'leetcode_58',
    title: 'Minimum Path Sum',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/minimum-path-sum/',
    tags: ['Dynamic Programming', 'Arrays & Hashing']
  },
  {
    id: 'leetcode_59',
    title: 'Search a 2D Matrix',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/search-a-2d-matrix/',
    tags: ['Binary Search', 'Arrays & Hashing']
  },
  {
    id: 'leetcode_60',
    title: 'Sort Colors',
    platform: 'LeetCode',
    difficulty: 'medium',
    url: 'https://leetcode.com/problems/sort-colors/',
    tags: ['Arrays & Hashing', 'Two Pointers']
  },

  // More Hard Problems
  {
    id: 'leetcode_61',
    title: 'N-Queens',
    platform: 'LeetCode',
    difficulty: 'hard',
    url: 'https://leetcode.com/problems/n-queens/',
    tags: ['Backtracking', 'Arrays & Hashing']
  },
  {
    id: 'leetcode_62',
    title: 'Sudoku Solver',
    platform: 'LeetCode',
    difficulty: 'hard',
    url: 'https://leetcode.com/problems/sudoku-solver/',
    tags: ['Backtracking', 'Arrays & Hashing']
  },
  {
    id: 'leetcode_63',
    title: 'First Missing Positive',
    platform: 'LeetCode',
    difficulty: 'hard',
    url: 'https://leetcode.com/problems/first-missing-positive/',
    tags: ['Arrays & Hashing', 'Two Pointers']
  },
  {
    id: 'leetcode_64',
    title: 'Serialize and Deserialize Binary Tree',
    platform: 'LeetCode',
    difficulty: 'hard',
    url: 'https://leetcode.com/problems/serialize-and-deserialize-binary-tree/',
    tags: ['Trees', 'String Algorithms']
  },
  {
    id: 'leetcode_65',
    title: 'Merge k Sorted Lists',
    platform: 'LeetCode',
    difficulty: 'hard',
    url: 'https://leetcode.com/problems/merge-k-sorted-lists/',
    tags: ['Linked List', 'Divide and Conquer']
  },

  // Final batch of CodeForces problems
  {
    id: 'codeforces_26',
    title: 'Next Round',
    platform: 'CodeForces',
    difficulty: 'easy',
    url: 'https://codeforces.com/problemset/problem/158/A',
    tags: ['Implementation', 'Arrays & Hashing']
  },
  {
    id: 'codeforces_27',
    title: 'Domino piling',
    platform: 'CodeForces',
    difficulty: 'easy',
    url: 'https://codeforces.com/problemset/problem/50/A',
    tags: ['Math & Geometry', 'Implementation']
  },
  {
    id: 'codeforces_28',
    title: 'Football',
    platform: 'CodeForces',
    difficulty: 'medium',
    url: 'https://codeforces.com/problemset/problem/96/A',
    tags: ['Implementation', 'String Algorithms']
  },
  {
    id: 'codeforces_29',
    title: 'Registration System',
    platform: 'CodeForces',
    difficulty: 'medium',
    url: 'https://codeforces.com/problemset/problem/4/C',
    tags: ['Implementation', 'Data Structures']
  },
  {
    id: 'codeforces_30',
    title: 'Cut Ribbon',
    platform: 'CodeForces',
    difficulty: 'hard',
    url: 'https://codeforces.com/problemset/problem/189/A',
    tags: ['Dynamic Programming', 'Implementation']
  },

  // Final batch of CodeChef problems
  {
    id: 'codechef_26',
    title: 'Reverse The Number',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/FLOW007',
    tags: ['Implementation', 'Math & Geometry']
  },
  {
    id: 'codechef_27',
    title: 'Finding Square Roots',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/FSQRT',
    tags: ['Implementation', 'Math & Geometry']
  },
  {
    id: 'codechef_28',
    title: 'Second Largest',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/FLOW017',
    tags: ['Implementation', 'Arrays & Hashing']
  },
  {
    id: 'codechef_29',
    title: 'Small Factorial',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/FLOW018',
    tags: ['Implementation', 'Math & Geometry']
  },
  {
    id: 'codechef_30',
    title: 'The Block Game',
    platform: 'CodeChef',
    difficulty: 'easy',
    url: 'https://www.codechef.com/problems/PALL01',
    tags: ['Implementation', 'String Algorithms']
  }
];

// Export functions for problem management
export const getAllProblems = async () => {
  return SAMPLE_PROBLEMS;
};

export const getProblemRecommendations = (problems, filters) => {
  return problems.filter(problem => {
    if (filters.difficulty !== 'all' && problem.difficulty !== filters.difficulty) {
      return false;
    }
    if (filters.topic !== 'all' && !problem.tags.includes(filters.topic)) {
      return false;
    }
    return true;
  });
};
