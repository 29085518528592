import React, { useState, useEffect } from 'react';
import { 
  getFilteredVideos, 
  TOPICS, 
  DIFFICULTY_LEVELS 
} from '../../services/resourceServices';
import './VideoResources.css';

const VideoResources = () => {
  const [videos, setVideos] = useState([]);
  const [filters, setFilters] = useState({
    topic: 'all',
    difficulty: 'all',
    searchQuery: ''
  });

  useEffect(() => {
    const filteredVideos = getFilteredVideos(filters);
    setVideos(filteredVideos);
  }, [filters]);

  const handleFilterChange = (filterType, value) => {
    setFilters(prev => ({
      ...prev,
      [filterType]: value
    }));
  };

  const getDifficultyColor = (difficulty) => {
    switch (difficulty.toLowerCase()) {
      case 'easy':
        return '#4CAF50';
      case 'medium':
        return '#FF9800';
      case 'hard':
        return '#f44336';
      default:
        return '#666';
    }
  };

  const formatDuration = (duration) => {
    return duration;
  };

  return (
    <div className="video-resources">
      <div className="filters-section">
        <h2>Learning Resources</h2>
        <div className="filters">
          <div className="filter-group">
            <label>Topic:</label>
            <select 
              value={filters.topic}
              onChange={(e) => handleFilterChange('topic', e.target.value)}
            >
              <option value="all">All Topics</option>
              {TOPICS.map(topic => (
                <option key={topic} value={topic}>{topic}</option>
              ))}
            </select>
          </div>

          <div className="filter-group">
            <label>Difficulty:</label>
            <select 
              value={filters.difficulty}
              onChange={(e) => handleFilterChange('difficulty', e.target.value)}
            >
              <option value="all">All Levels</option>
              {DIFFICULTY_LEVELS.map(level => (
                <option key={level} value={level}>{level}</option>
              ))}
            </select>
          </div>

          <div className="filter-group search">
            <input
              type="text"
              placeholder="Search videos..."
              value={filters.searchQuery}
              onChange={(e) => handleFilterChange('searchQuery', e.target.value)}
            />
          </div>
        </div>
      </div>

      {videos.length === 0 ? (
        <div className="no-videos">
          <p>No videos found matching your criteria.</p>
          <p>Try adjusting your filters or search query.</p>
        </div>
      ) : (
        <div className="videos-grid">
          {videos.map(video => (
            <div key={video.id} className="video-card">
              <div className="video-header">
                <span 
                  className="difficulty-badge"
                  style={{ backgroundColor: getDifficultyColor(video.difficulty) }}
                >
                  {video.difficulty}
                </span>
                <span className="duration">{formatDuration(video.duration)}</span>
              </div>
              <h3>{video.title}</h3>
              <div className="video-details">
                <p className="channel">{video.channel}</p>
                <p className="topic">{video.topic}</p>
                <p className="description">{video.description}</p>
                <div className="tags">
                  {video.tags.map(tag => (
                    <span key={tag} className="tag">#{tag}</span>
                  ))}
                </div>
              </div>
              <a 
                href={video.url} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="watch-button"
              >
                Watch Now
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VideoResources;
