// API integration for different coding platforms

import { db } from '../firebase/config';
import { 
  collection, 
  query, 
  getDocs, 
  where 
} from 'firebase/firestore';

export const fetchLeetCodeStats = async (username) => {
  try {
    const response = await fetch(`https://leetcode-stats-api.herokuapp.com/${username}`);
    return await response.json();
  } catch (error) {
    console.error('Error fetching LeetCode stats:', error);
    return null;
  }
};

export const fetchCodeforcesStats = async (handle) => {
  try {
    const response = await fetch(`https://codeforces.com/api/user.info?handles=${handle}`);
    const data = await response.json();
    
    // Fetch rating history
    const ratingResponse = await fetch(`https://codeforces.com/api/user.rating?handle=${handle}`);
    const ratingData = await ratingResponse.json();
    
    return {
      ...data,
      ratingHistory: ratingData.result
    };
  } catch (error) {
    console.error('Error fetching Codeforces stats:', error);
    return null;
  }
};

export const fetchCodeChefStats = async (username) => {
  try {
    const response = await fetch(`https://competitive-coding-api.herokuapp.com/api/codechef/${username}`);
    const data = await response.json();
    
    // Extract rating history from the response
    const ratingHistory = data.contest_ratings?.map(contest => ({
      contestName: contest.name,
      rank: contest.rank,
      rating: parseInt(contest.rating),
      timestamp: new Date(contest.end_date).getTime()
    })) || [];
    
    return {
      ...data,
      ratingHistory
    };
  } catch (error) {
    console.error('Error fetching CodeChef stats:', error);
    return null;
  }
};

export const fetchAllPlatformStats = async (usernames) => {
  const { leetcodeUsername, codeforcesHandle, codechefUsername } = usernames;
  
  try {
    const [leetcodeStats, codeforcesStats, codechefStats] = await Promise.all([
      leetcodeUsername ? fetchLeetCodeStats(leetcodeUsername) : null,
      codeforcesHandle ? fetchCodeforcesStats(codeforcesHandle) : null,
      codechefUsername ? fetchCodeChefStats(codechefUsername) : null
    ]);

    return {
      leetcode: leetcodeStats,
      codeforces: codeforcesStats,
      codechef: codechefStats
    };
  } catch (error) {
    console.error('Error fetching platform stats:', error);
    return {
      leetcode: null,
      codeforces: null,
      codechef: null
    };
  }
};

// LeetCode API endpoints
export const fetchLeetCodeProblems = async (username) => {
  try {
    // Using LeetCode's GraphQL API
    const response = await fetch('https://leetcode.com/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
          query userProblemsSolved($username: String!) {
            matchedUser(username: $username) {
              submitStats {
                acSubmissionNum {
                  difficulty
                  count
                }
              }
              submitStatsGlobal {
                problemsSolved
              }
              problemsSolvedBeatsStats {
                difficulty
                percentage
              }
            }
            allProblems {
              title
              titleSlug
              difficulty
              topicTags {
                name
              }
              status
              stats
              isPaidOnly
              companyTags {
                name
              }
              frequency
            }
          }
        `,
        variables: { username }
      })
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching LeetCode problems:', error);
    return null;
  }
};

// Function to fetch CodeForces problems
export const fetchCodeforcesProblems = async () => {
  try {
    const response = await fetch('https://codeforces.com/api/problemset.problems');
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.error('Error fetching CodeForces problems:', error);
    return null;
  }
};

// Function to get user's solved problems
export const fetchUserSolvedProblems = async (userId) => {
  try {
    const solvedRef = collection(db, 'solvedProblems');
    const q = query(solvedRef, where('userId', '==', userId));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => doc.data());
  } catch (error) {
    console.error('Error fetching solved problems:', error);
    return [];
  }
};

// Important topics for coding interviews
const IMPORTANT_TOPICS = [
  'Array', 'String', 'Hash Table', 'Dynamic Programming',
  'Math', 'Sorting', 'Greedy', 'Depth-First Search',
  'Binary Search', 'Breadth-First Search', 'Tree',
  'Binary Tree', 'Matrix', 'Two Pointers', 'Stack',
  'Heap', 'Graph', 'Sliding Window'
];

// Function to score problem importance
const scoreProblemImportance = (problem) => {
  let score = 0;
  
  // Score based on company tags (if available)
  if (problem.companyTags?.length) {
    score += problem.companyTags.length * 2;
  }

  // Score based on frequency
  if (problem.frequency) {
    score += parseInt(problem.frequency) * 3;
  }

  // Score based on topics
  const problemTopics = problem.topicTags.map(tag => tag.name);
  const importantTopicsCount = problemTopics.filter(topic => 
    IMPORTANT_TOPICS.includes(topic)
  ).length;
  score += importantTopicsCount * 5;

  return score;
};

export const generateRecommendations = async (userId, platforms) => {
  try {
    // Fetch user's solved problems
    const solvedProblems = await fetchUserSolvedProblems(userId);
    const solvedSet = new Set(solvedProblems.map(p => p.problemId));

    let recommendations = [];

    // Fetch LeetCode problems if username is provided
    if (platforms.leetcodeUsername) {
      const leetcodeData = await fetchLeetCodeProblems(platforms.leetcodeUsername);
      console.log('LeetCode data:', leetcodeData); // Debug log

      if (leetcodeData?.data?.allProblems) {
        const problems = leetcodeData.data.allProblems
          .filter(p => !solvedSet.has(p.titleSlug) && !p.isPaidOnly)
          .map(p => ({
            title: p.title,
            titleSlug: p.titleSlug,
            difficulty: p.difficulty,
            platform: 'LeetCode',
            topicTags: p.topicTags || [],
            importance: scoreProblemImportance(p)
          }));
        recommendations.push(...problems);
      }
    }

    // Fetch CodeForces problems if handle is provided
    if (platforms.codeforcesHandle) {
      const codeforcesData = await fetchCodeforcesProblems();
      console.log('CodeForces data:', codeforcesData); // Debug log

      if (codeforcesData?.problems) {
        const problems = codeforcesData.problems
          .filter(p => !solvedSet.has(`cf_${p.contestId}_${p.index}`))
          .map(p => ({
            title: p.name,
            difficulty: p.rating?.toString() || 'Unknown',
            platform: 'CodeForces',
            contestId: p.contestId,
            index: p.index,
            tags: p.tags,
            importance: p.rating ? Math.min(Math.max(p.rating / 500, 1), 5) : 3
          }));
        recommendations.push(...problems);
      }
    }

    // Sort by importance and limit to top 10
    recommendations.sort((a, b) => b.importance - a.importance);
    const topRecommendations = recommendations.slice(0, 10);
    
    // Debug log
    console.log('Final recommendations:', topRecommendations);
    
    return topRecommendations;
  } catch (error) {
    console.error('Error generating recommendations:', error);
    return [];
  }
};
