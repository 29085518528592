import React, { useState, useEffect } from 'react';
import { getAllProblems, getProblemRecommendations } from '../../services/problemServices';
import './RecommendedProblems.css';

const TOPICS = [
  "Arrays & Hashing",
  "Two Pointers",
  "Stack",
  "Binary Search",
  "Sliding Window",
  "Linked List",
  "Trees",
  "Graphs",
  "Dynamic Programming",
  "Greedy",
  "Math & Geometry",
  "String Algorithms",
  "Implementation"
];

const RecommendedProblems = () => {
  const [problems, setProblems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState({
    difficulty: 'all',
    topic: 'all'
  });

  useEffect(() => {
    const fetchProblems = async () => {
      try {
        setLoading(true);
        const allProblems = await getAllProblems();
        const filteredProblems = getProblemRecommendations(allProblems, filter);
        setProblems(filteredProblems);
      } catch (err) {
        setError('Failed to fetch problems. Please try again later.');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchProblems();
  }, [filter]);

  const handleFilterChange = (filterType, value) => {
    setFilter(prev => ({
      ...prev,
      [filterType]: value
    }));
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loader"></div>
        <p>Loading problems...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p className="error-message">{error}</p>
        <button onClick={() => window.location.reload()}>Try Again</button>
      </div>
    );
  }

  return (
    <div className="problems-container">
      <div className="filters-section">
        <h2>Filter Problems</h2>
        <div className="filter-controls">
          <div className="filter-group">
            <label>Difficulty Level</label>
            <div className="difficulty-buttons">
              <button
                className={`difficulty-button ${filter.difficulty === 'all' ? 'active' : ''}`}
                onClick={() => handleFilterChange('difficulty', 'all')}
              >
                All Difficulties
              </button>
              <button
                className={`difficulty-button ${filter.difficulty === 'easy' ? 'active' : ''}`}
                style={{ '--button-color': '#00b8a3', '--text-color': 'white' }}
                onClick={() => handleFilterChange('difficulty', 'easy')}
              >
                Easy
              </button>
              <button
                className={`difficulty-button ${filter.difficulty === 'medium' ? 'active' : ''}`}
                style={{ '--button-color': '#ffa116', '--text-color': 'white' }}
                onClick={() => handleFilterChange('difficulty', 'medium')}
              >
                Medium
              </button>
              <button
                className={`difficulty-button ${filter.difficulty === 'hard' ? 'active' : ''}`}
                style={{ '--button-color': '#ff375f', '--text-color': 'white' }}
                onClick={() => handleFilterChange('difficulty', 'hard')}
              >
                Hard
              </button>
            </div>
          </div>

          <div className="filter-group">
            <label>Problem Topic</label>
            <select
              value={filter.topic}
              onChange={(e) => handleFilterChange('topic', e.target.value)}
              className="topic-select"
            >
              <option value="all">All Topics</option>
              {TOPICS.map(topic => (
                <option key={topic} value={topic}>{topic}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="problems-grid">
        {problems.map(problem => (
          <div key={problem.id} className="problem-card">
            <div className="problem-header">
              <h3>{problem.title}</h3>
              <span className={`platform-badge ${problem.platform.toLowerCase()}`}>
                {problem.platform}
              </span>
            </div>
            <div className="problem-details">
              <span className={`difficulty-badge ${problem.difficulty}`}>
                {problem.difficulty.charAt(0).toUpperCase() + problem.difficulty.slice(1)}
              </span>
              {problem.tags && (
                <div className="tags">
                  {problem.tags.slice(0, 3).map(tag => (
                    <span key={tag} className="tag">{tag}</span>
                  ))}
                </div>
              )}
            </div>
            <a
              href={problem.url}
              target="_blank"
              rel="noopener noreferrer"
              className="solve-button"
            >
              Solve Problem
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecommendedProblems;
