import axios from 'axios';

// API endpoints
const CODEFORCES_CONTESTS_API = 'https://codeforces.com/api/contest.list';
const LEETCODE_CONTESTS_API = 'https://leetcode.com/contest/api/list/';
const CODECHEF_CONTESTS_API = 'https://www.codechef.com/api/list/contests/all';

// Function to check if a contest is upcoming
const isUpcomingContest = (startTime) => {
  const currentTime = new Date().getTime();
  const contestTime = new Date(startTime).getTime();
  return contestTime > currentTime;
};

// Function to fetch CodeForces contests
const fetchCodeforcesContests = async () => {
  try {
    const response = await axios.get(CODEFORCES_CONTESTS_API);
    const contests = response.data.result
      .filter(contest => contest.phase === 'BEFORE') // Only get upcoming contests
      .map(contest => {
        const startTime = new Date(contest.startTimeSeconds * 1000).toISOString();
        // Only include if it's an upcoming contest
        if (!isUpcomingContest(startTime)) return null;

        return {
          id: `cf_${contest.id}`,
          title: contest.name,
          platform: 'CodeForces',
          startTime,
          duration: `${Math.round(contest.durationSeconds / 3600 * 10) / 10} hours`,
          url: `https://codeforces.com/contests/${contest.id}`,
          difficulty: contest.name.includes('Div. 1') ? 'Hard' : 
                     contest.name.includes('Div. 2') ? 'Medium' : 
                     contest.name.includes('Div. 3') ? 'Easy' : 'Mixed',
          registrationOpen: true
        };
      })
      .filter(Boolean); // Remove null entries
    
    return contests;
  } catch (error) {
    console.error('Error fetching CodeForces contests:', error);
    return [];
  }
};

// Function to fetch LeetCode contests
const fetchLeetCodeContests = async () => {
  try {
    const response = await axios.get(LEETCODE_CONTESTS_API);
    const contests = response.data.contests
      .filter(contest => isUpcomingContest(contest.start_time))
      .map(contest => ({
        id: `lc_${contest.title_slug}`,
        title: contest.title,
        platform: 'LeetCode',
        startTime: new Date(contest.start_time).toISOString(),
        duration: '1.5 hours',
        url: `https://leetcode.com/contest/${contest.title_slug}`,
        difficulty: 'Mixed',
        registrationOpen: true
      }));

    return contests;
  } catch (error) {
    console.error('Error fetching LeetCode contests:', error);
    return [];
  }
};

// Function to fetch CodeChef contests
const fetchCodeChefContests = async () => {
  try {
    const response = await axios.get(CODECHEF_CONTESTS_API);
    
    // Combine future and present contests
    const allContests = [
      ...(response.data.future_contests || []),
      ...(response.data.present_contests || [])
    ]
    .filter(contest => isUpcomingContest(contest.start_date))
    .map(contest => ({
      id: `cc_${contest.contest_code}`,
      title: contest.contest_name,
      platform: 'CodeChef',
      startTime: new Date(contest.start_date).toISOString(),
      duration: `${contest.contest_duration} hours`,
      url: `https://www.codechef.com/${contest.contest_code}`,
      difficulty: contest.contest_name.includes('Starters') ? 'Mixed' :
                 contest.contest_name.includes('Lunchtime') ? 'Easy' : 'Medium',
      registrationOpen: true
    }));
      
    return allContests;
  } catch (error) {
    console.error('Error fetching CodeChef contests:', error);
    return [];
  }
};

// Function to fetch all upcoming contests
export const fetchUpcomingContests = async () => {
  try {
    // Fetch contests from all platforms in parallel
    const [codeforcesContests, leetcodeContests, codechefContests] = await Promise.all([
      fetchCodeforcesContests(),
      fetchLeetCodeContests(),
      fetchCodeChefContests()
    ]);

    // Combine all contests and sort by start time
    const allContests = [...codeforcesContests, ...leetcodeContests, ...codechefContests]
      .sort((a, b) => new Date(a.startTime) - new Date(b.startTime));

    return allContests;
  } catch (error) {
    console.error('Error fetching contests:', error);
    return [];
  }
};

// Function to get contests from a specific platform
export const getContestsByPlatform = async (platform) => {
  const allContests = await fetchUpcomingContests();
  return allContests.filter(contest => 
    contest.platform.toLowerCase() === platform.toLowerCase()
  );
};

// Function to get contests happening within the next N days
export const getUpcomingContestsInDays = async (days) => {
  const allContests = await fetchUpcomingContests();
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime() + (days * 24 * 60 * 60 * 1000));
  
  return allContests.filter(contest => {
    const contestDate = new Date(contest.startTime);
    return contestDate >= currentDate && contestDate <= futureDate;
  });
};

// Function to check if registration is open for a contest
export const isRegistrationOpen = async (contestId) => {
  const allContests = await fetchUpcomingContests();
  const contest = allContests.find(c => c.id === contestId);
  return contest ? contest.registrationOpen : false;
};

// Function to get contest details by ID
export const getContestById = async (contestId) => {
  const allContests = await fetchUpcomingContests();
  return allContests.find(contest => contest.id === contestId);
};
