import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/config';
import './LearningResources.css';

const LearningResources = () => {
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState({
    difficulty: 'all',
    topic: 'all'
  });

  // Comprehensive list of DSA topics
  const topics = [
    "Arrays & Hashing",
    "Two Pointers",
    "Stack",
    "Binary Search",
    "Sliding Window",
    "Linked List",
    "Trees",
    "Tries",
    "Heap / Priority Queue",
    "Backtracking",
    "Graphs",
    "Dynamic Programming",
    "Greedy",
    "Intervals",
    "Math & Geometry",
    "Bit Manipulation"
  ];

  // AI-curated video resources
  const videoResources = [
    {
      id: "v1",
      title: "Master Dynamic Programming - Step by Step",
      type: "Video",
      platform: "YouTube",
      creator: "Neetcode",
      url: "https://www.youtube.com/watch?v=oBt53YbR9Kk",
      topic: "Dynamic Programming",
      difficulty: "Intermediate",
      duration: "4 hours",
      rating: 4.9,
      views: "1M+",
      description: "Complete Dynamic Programming tutorial with step-by-step explanations and visualizations",
      aiVerified: true,
      prerequisites: ["Basic Recursion", "Time Complexity"],
      keyPoints: [
        "Understanding recursion tree",
        "Memoization techniques",
        "Tabulation methods",
        "Space optimization"
      ],
      aiRecommendation: "Highly recommended for its clear explanations and comprehensive coverage"
    },
    {
      id: "v2",
      title: "Graph Algorithms for Technical Interviews",
      type: "Video",
      platform: "YouTube",
      creator: "Tech With Tim",
      url: "https://www.youtube.com/watch?v=tWVWeAqZ0WU",
      topic: "Graphs",
      difficulty: "Advanced",
      duration: "2 hours",
      rating: 4.8,
      views: "500K+",
      description: "In-depth coverage of graph algorithms with real interview questions",
      aiVerified: true,
      prerequisites: ["Basic Graph Theory", "DFS/BFS Basics"],
      keyPoints: [
        "Dijkstra's Algorithm",
        "Minimum Spanning Trees",
        "Topological Sort",
        "Union Find"
      ],
      aiRecommendation: "Excellent for interview preparation with practical examples"
    },
    {
      id: "v3",
      title: "Arrays & String Manipulation Masterclass",
      type: "Video",
      platform: "YouTube",
      creator: "FreeCodeCamp",
      url: "https://www.youtube.com/watch?v=8RLXIJjvPUA",
      topic: "Arrays & Hashing",
      difficulty: "Beginner",
      duration: "1.5 hours",
      rating: 4.9,
      views: "750K+",
      description: "Comprehensive guide to array and string manipulation techniques",
      aiVerified: true,
      prerequisites: ["Basic Programming"],
      keyPoints: [
        "Array operations",
        "String manipulation",
        "Two-pointer technique",
        "Sliding window basics"
      ],
      aiRecommendation: "Perfect for beginners learning fundamental array operations"
    },
    {
      id: "v4",
      title: "Binary Trees and BST Explained",
      type: "Video",
      platform: "YouTube",
      creator: "CS Dojo",
      url: "https://www.youtube.com/watch?v=fAAZixBzIAI",
      topic: "Trees",
      difficulty: "Intermediate",
      duration: "1 hour",
      rating: 4.7,
      views: "600K+",
      description: "Clear explanation of binary trees and binary search trees",
      aiVerified: true,
      prerequisites: ["Basic Data Structures", "Recursion"],
      keyPoints: [
        "Tree traversals",
        "BST operations",
        "Balancing trees",
        "Common tree problems"
      ],
      aiRecommendation: "Great visualization of tree concepts with practical examples"
    },
    {
      id: "v5",
      title: "Mastering Bit Manipulation",
      type: "Video",
      platform: "YouTube",
      creator: "Back To Back SWE",
      url: "https://www.youtube.com/watch?v=NLKQEOgBAnw",
      topic: "Bit Manipulation",
      difficulty: "Advanced",
      duration: "45 minutes",
      rating: 4.8,
      views: "300K+",
      description: "Deep dive into bit manipulation techniques and tricks",
      aiVerified: true,
      prerequisites: ["Binary Number System", "Basic Operators"],
      keyPoints: [
        "Bitwise operators",
        "Bit manipulation tricks",
        "Common bit problems",
        "Optimization techniques"
      ],
      aiRecommendation: "Excellent coverage of advanced bit manipulation concepts"
    }
  ];

  useEffect(() => {
    fetchResources();
  }, [filter]);

  const fetchResources = async () => {
    try {
      setLoading(true);
      setError(null);

      let filteredResources = [...videoResources];

      if (filter.difficulty !== 'all') {
        filteredResources = filteredResources.filter(
          r => r.difficulty.toLowerCase() === filter.difficulty.toLowerCase()
        );
      }

      if (filter.topic !== 'all') {
        filteredResources = filteredResources.filter(
          r => r.topic === filter.topic
        );
      }

      // Sort by rating and AI verification
      filteredResources.sort((a, b) => {
        if (a.aiVerified !== b.aiVerified) {
          return b.aiVerified ? 1 : -1;
        }
        return b.rating - a.rating;
      });

      setResources(filteredResources);
    } catch (err) {
      console.error('Error fetching resources:', err);
      setError('Failed to load learning resources. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="resources-container">
        <div className="loading">Loading learning resources...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="resources-container">
        <div className="error">{error}</div>
        <button onClick={fetchResources} className="retry-button">
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="resources-container">
      <div className="resources-header">
        <h2>Resources</h2>
        <div className="filters">
          <select
            value={filter.difficulty}
            onChange={(e) => setFilter({ ...filter, difficulty: e.target.value })}
            className="filter-select"
          >
            <option value="all">All Levels</option>
            <option value="beginner">Beginner</option>
            <option value="intermediate">Intermediate</option>
            <option value="advanced">Advanced</option>
          </select>

          <select
            value={filter.topic}
            onChange={(e) => setFilter({ ...filter, topic: e.target.value })}
            className="filter-select"
          >
            <option value="all">All Topics</option>
            {topics.map(topic => (
              <option key={topic} value={topic}>{topic}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="resources-grid">
        {resources.map(resource => (
          <div key={resource.id} className="resource-card">
            <div className="resource-header">
              <div className={`resource-type ${resource.type.toLowerCase()}`}>
                {resource.type}
              </div>
              <div className={`difficulty-badge ${resource.difficulty.toLowerCase()}`}>
                {resource.difficulty}
              </div>
            </div>

            <h3 className="resource-title">{resource.title}</h3>
            
            <div className="resource-creator">
              <span className="label">By:</span> {resource.creator} on {resource.platform}
            </div>

            <div className="resource-topic">
              <span className="label">Topic:</span> {resource.topic}
            </div>

            <div className="resource-stats">
              <div className="stat">
                <span className="label">Rating:</span> {resource.rating}/5
              </div>
              <div className="stat">
                <span className="label">Duration:</span> {resource.duration}
              </div>
              <div className="stat">
                <span className="label">Views:</span> {resource.views}
              </div>
            </div>

            <div className="resource-description">
              <span className="label">Description:</span>
              <p>{resource.description}</p>
            </div>

            <div className="ai-recommendation">
              <span className="label">AI Recommendation:</span>
              <p>{resource.aiRecommendation}</p>
            </div>

            <div className="prerequisites">
              <span className="label">Prerequisites:</span>
              <div className="prereq-list">
                {resource.prerequisites.map((prereq, index) => (
                  <span key={index} className="prereq-tag">
                    {prereq}
                  </span>
                ))}
              </div>
            </div>

            <div className="key-points">
              <span className="label">Key Points:</span>
              <ul>
                {resource.keyPoints.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>

            <a
              href={resource.url}
              target="_blank"
              rel="noopener noreferrer"
              className="resource-button"
            >
              Watch Now
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LearningResources;
