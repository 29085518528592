// Production Firebase Configuration
const prodConfig = {
  apiKey: "AIzaSyCuF0N2QTQnX_OGEPTojWSekY5xB7hGJ_c",
  authDomain: "codecracker-9eaf0.firebaseapp.com",
  projectId: "codecracker-9eaf0",
  storageBucket: "codecracker-9eaf0.appspot.com",
  messagingSenderId: "1048380917183",
  appId: "1:1048380917183:web:a7b66c0349e8c74ed34d0a",
  measurementId: "G-M9C4LD6GR7",
};

export default prodConfig;
