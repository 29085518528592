import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import './UserProfile.css';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const ShareableProfile = () => {
  const { shareId } = useParams();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchProfile();
  }, [shareId]);

  const fetchProfile = async () => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('shareId', '==', shareId));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setError('Profile not found');
        setLoading(false);
        return;
      }

      const userData = querySnapshot.docs[0].data();
      setProfile(userData);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching profile:', err);
      setError('Failed to load profile');
      setLoading(false);
    }
  };

  const getRatingChartData = () => {
    if (!profile?.stats) return null;

    const datasets = [];

    // Add CodeForces data
    if (profile.stats.codeforces?.ratingHistory?.length > 0) {
      const cfData = profile.stats.codeforces.ratingHistory.map(entry => ({
        x: new Date(entry.ratingUpdateTimeSeconds * 1000).toISOString(),
        y: entry.newRating
      }));
      datasets.push({
        label: 'CodeForces',
        data: cfData,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.1
      });
    }

    // Add CodeChef data
    if (profile.stats.codechef?.ratingHistory?.length > 0) {
      const ccData = profile.stats.codechef.ratingHistory.map(entry => ({
        x: new Date(entry.timestamp).toISOString(),
        y: entry.rating
      }));
      datasets.push({
        label: 'CodeChef',
        data: ccData,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.1
      });
    }

    return {
      datasets
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        align: 'center',
        labels: {
          padding: 20
        }
      },
      title: {
        display: true,
        text: 'Rating Progress Over Time',
        align: 'start',
        font: {
          size: 16,
          weight: 'bold'
        },
        padding: {
          top: 10,
          bottom: 30
        }
      }
    },
    scales: {
      x: {
        type: 'time',
        adapters: {
          date: {
            locale: enUS
          }
        },
        time: {
          unit: 'month',
          displayFormats: {
            month: 'MMM yyyy'
          }
        },
        title: {
          display: true,
          text: 'Date',
          padding: {
            top: 10
          }
        }
      },
      y: {
        title: {
          display: true,
          text: 'Rating',
          padding: {
            bottom: 10
          }
        },
        min: 0
      }
    }
  };

  if (loading) {
    return (
      <div className="shared-profile-container">
        <div className="loading">Loading profile...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="shared-profile-container">
        <div className="error">{error}</div>
      </div>
    );
  }

  if (!profile) {
    return (
      <div className="shared-profile-container">
        <div className="error">Profile not found</div>
      </div>
    );
  }

  return (
    <div className="shared-profile-container">
      <div className="shared-profile-content">
        <h1 className="shared-profile-title">CodeCracker Profile</h1>
        
        {/* Coding Profiles Section */}
        <div className="platform-usernames">
          <h2>Coding Profiles</h2>
          <div className="platform-form">
            <div className="form-group">
              <label>LeetCode:</label>
              <span className="username-display">
                {profile.platforms?.leetcodeUsername || 'Not set'}
              </span>
            </div>

            <div className="form-group">
              <label>CodeForces:</label>
              <span className="username-display">
                {profile.platforms?.codeforcesHandle || 'Not set'}
              </span>
            </div>

            <div className="form-group">
              <label>CodeChef:</label>
              <span className="username-display">
                {profile.platforms?.codechefUsername || 'Not set'}
              </span>
            </div>
          </div>
        </div>

        {/* Platform Stats */}
        {profile.stats && (
          <>
            <div className="platform-stats-grid">
              <div className="platform-card">
                <h3>LeetCode</h3>
                <div className="stat-item">
                  <span>Problems Solved:</span>
                  <span>{profile.stats.leetcode?.totalSolved || '0'}</span>
                </div>
                <div className="stat-item">
                  <span>Acceptance Rate:</span>
                  <span>{profile.stats.leetcode?.acceptanceRate || '0'}%</span>
                </div>
              </div>

              <div className="platform-card">
                <h3>CodeForces</h3>
                <div className="stat-item">
                  <span>Rating:</span>
                  <span>{profile.stats.codeforces?.result?.[0]?.rating || '0'}</span>
                </div>
                <div className="stat-item">
                  <span>Max Rating:</span>
                  <span>{profile.stats.codeforces?.result?.[0]?.maxRating || '0'}</span>
                </div>
              </div>

              <div className="platform-card">
                <h3>CodeChef</h3>
                <div className="stat-item">
                  <span>Rating:</span>
                  <span>{profile.stats.codechef?.currentRating || '0'}</span>
                </div>
                <div className="stat-item">
                  <span>Problems Solved:</span>
                  <span>{profile.stats.codechef?.problemsSolved || '0'}</span>
                </div>
              </div>
            </div>

            {/* Rating Progress Chart */}
            <div className="chart-section">
              <h2>Rating Progress Over Time</h2>
              <div className="chart-container">
                {getRatingChartData() && (
                  <Line 
                    data={getRatingChartData()} 
                    options={chartOptions}
                    height={300}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ShareableProfile;
